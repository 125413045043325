
<template>
  <div>
<!--  类别  -->
    <div class="classesList">
      <div v-for="(item, index) in classesList" :key="index">
        <div class="classes" @click="handleClick(item)" :style="classesId === item.id ? 'color:#ffffff' : ''">
          <div class="classesName">{{ item.name }}</div>
          <div class="update" @click="option(1, item.id)">改</div>
          <div class="update" @click="classesDelv(item.id)">删</div>
        </div>
      </div>
      <div class="classesSet" @click="dialogVisible_classes_add = true">
        +
      </div>
      <el-dialog
          title="添加新类别"
          :visible.sync="dialogVisible_classes_add"
          width="30%">
        <el-input v-model="classes_Name" placeholder="请输入新的内容" clearable></el-input>
        <span slot="footer" class="dialog-footer">
          <el-button @click="dialogVisible_classes_add = false">取 消</el-button>
          <el-button type="primary" @click="classesSavev()">确 定</el-button>
        </span>
      </el-dialog>
      <el-dialog
          title="修改类别"
          :visible.sync="dialogVisible_classes_update"
          width="30%">
        <el-input v-model="classes_Name" placeholder="请输入修改的内容" clearable></el-input>
        <span slot="footer" class="dialog-footer">
          <el-button @click="dialogVisible_classes_update = false">取 消</el-button>
          <el-button type="primary" @click="classesUpdatev();">确 定</el-button>
        </span>
      </el-dialog>
    </div>

<!--  关键词  -->
    <div class="keywordList">
      <div class="keywordSet" @click="dialogVisible_keyword_add = true">添加关键词</div>
      <div class="len">已有关键词数：{{ keywordList.length }} 个</div>
      <div v-for="(item, index) in keywordList" :key="index" class="keywords">
        <div class="keyword">{{ item.keyword }}</div>
        <div class="Num">通过次数：{{ item.passNum }}</div>
        <div class="Num">驳回次数：{{ item.rejectNum }}</div>
        <div @click="option(2, item.id)" class="unify">修改</div>
        <div @click="keywordDelv(item.id)" class="unify">删除</div>
        <el-button @click="keywordUpdatesort(item, 1, index)" type="primary" plain size="small" :disabled="index === 0">向上</el-button>
        <el-button @click="keywordUpdatesort(item, 2, index)" type="primary" plain size="small" :disabled="index === keywordList.length-1">向下</el-button>
      </div>
      <el-dialog
          title="添加新关键词"
          :visible.sync="dialogVisible_keyword_add"
          width="30%">
        <el-input v-model="keyword_Name" placeholder="请输入新的内容，长度小于12个汉字。" clearable maxlength="12" show-word-limit></el-input>
        <span slot="footer" class="dialog-footer">
          <el-button @click="dialogVisible_keyword_add = false">取 消</el-button>
          <el-button type="primary" @click="keywordSavev()">确 定</el-button>
        </span>
      </el-dialog>
      <el-dialog
          title="修改关键词"
          :visible.sync="dialogVisible_keyword_update"
          width="30%">
        <el-input v-model="keyword_Name" placeholder="请输入修改的内容，长度小于12个汉字。" clearable maxlength="12" show-word-limit></el-input>
        <span slot="footer" class="dialog-footer">
          <el-button @click="dialogVisible_keyword_update = false">取 消</el-button>
          <el-button type="primary" @click="keywordUpdatev()">确 定</el-button>
        </span>
      </el-dialog>

    </div>

  </div>
</template>

<script>
export default {
  components: {

  },
  data() {
    return {
      /**
       * 类别切换
       */
      classesList: [], //所有的类别
      classesId: 0, //选中/当前显示的类别
      classes_Name: '', //添加|修改-新的类别名
      classes_Id: '', //修改-新的类别名
      //弹出层
      dialogVisible_classes_add: false,
      dialogVisible_classes_update: false,
      dialogVisible_title: '',


      /**
       * 关键词
       */
      keywordList: [],
      keyword_Name: '', //添加|修改-新的关键词
      keyword_id: '', //修改-新的类别名
      //弹出层
      dialogVisible_keyword_add: false,
      dialogVisible_keyword_update: false,

    }
  },
  created() {
    this.classesGet()
        .then(this.keywordGet);
  },
  methods: {
    //修改 类别或关键词 弹出层并接受内容
    option(is, id) {
      if (is === 1) {
        this.dialogVisible_classes_update = true;
        this.classes_id = id;
      } else {
        this.dialogVisible_keyword_update = true;
        this.keyword_id = id;
      }
    },

    /**
     * 类别
     */
    //类别切换
    handleClick(item) {
      // console.log(item);
      this.classesId = item.id;
      this.keywordGet();
    },
    //查询所有类别
    async classesGet() {
      const { data: res } = await this.$http.get(
          "/tbThirdPartyWangAdministrationKeywordClasses/getv"
      );
      // console.log(res)
      if (res.code == 200) {
        this.classesList = res.message;
        this.classesId = this.classesList[0].id;
      }
    },
    //添加新类别
    async classesSavev() {
      let tbThirdPartyWangAdministrationKeywordClasses = {
        name: this.classes_Name
      }
      const { data: res } = await this.$http.post(
          "/tbThirdPartyWangAdministrationKeywordClasses/savev",
          tbThirdPartyWangAdministrationKeywordClasses
      );
      // console.log(res)
      if (res.code == 200) {
        this.$message.success("添加成功");
      } else {
        this.$message.error("添加失败");
      }
      this.dialogVisible_classes_add = false
      //刷新类别数据
      this.classesGet();
    },
    //修改类别
    async classesUpdatev() {
      let tbThirdPartyWangAdministrationKeywordClasses = {
        id: this.classes_id,
        name: this.classes_Name
      }
      const { data: res } = await this.$http.post(
          "/tbThirdPartyWangAdministrationKeywordClasses/updatev",
          tbThirdPartyWangAdministrationKeywordClasses
      );
      // console.log(res)
      if (res.code == 200 && res.message == true) {
        this.$message.success("修改成功");
      } else {
        this.$message.error("修改失败");
      }
      this.dialogVisible_classes_update = false
      //刷新类别数据
      this.classesGet();
    },
    //删除类别
    async classesDelv(id) {
      const { data: res } = await this.$http.delete(
          "/tbThirdPartyWangAdministrationKeywordClasses/delv?i="+id
      );
      // console.log(res)
      if (res.code == 200 && res.message == true) {
        this.$message.success("删除成功");
      } else {
        this.$message.error("删除失败");
      }
      //刷新类别数据
      this.classesId = this.classesList[0].id;
      this.classesGet();
    },

    /**
     * 关键词
     */
    //根据类别id，查询对应的关键词
    async keywordGet() {
      const { data: res } = await this.$http.get(
          "/tbThirdPartyWangAdministrationKeyword/getIdv?classesId=" + this.classesId
      );
      // console.log(res)
      if (res.code == 200) {
        this.keywordList = res.message;
      }
    },
    //添加新关键词
    async keywordSavev() {
      let tbThirdPartyWangAdministrationKeywordVo = {
        classesId: this.classesId,
        keyword: this.keyword_Name
      }
      const { data: res } = await this.$http.post(
          "/tbThirdPartyWangAdministrationKeyword/savev",
          tbThirdPartyWangAdministrationKeywordVo
      );
      // console.log(res)
      if (res.code == 200) {
        this.$message.success("添加成功");
      } else {
        this.$message.error("添加失败");
      }
      this.dialogVisible_keyword_add = false;
      //刷新关键词数据
      this.keywordGet();
    },
    //修改关键词内容
    async keywordUpdatev() {
      let tbThirdPartyWangAdministrationKeywordVo = {
        id: this.keyword_id,
        keyword: this.keyword_Name
      }
      const { data: res } = await this.$http.post(
          "/tbThirdPartyWangAdministrationKeyword/updatev",
          tbThirdPartyWangAdministrationKeywordVo
      );
      // console.log(res)
      if (res.code == 200 && res.message == true) {
        this.$message.success("修改成功");
      } else {
        this.$message.error("修改失败");
      }
      this.dialogVisible_keyword_update = false;
      //刷新关键词数据
      this.keywordGet();
    },
    //修改关键词排序位置
    async keywordUpdatesort(item, is, index) {
      let lastId = item.id;
      let nowId = null;
      if (is === 1) {
        nowId = this.keywordList[index-1].id;
      } else {
        nowId = this.keywordList[index+1].id;
      }
      const { data: res } = await this.$http.get(
          "/tbThirdPartyWangAdministrationKeyword/updatesort" +
          "?keywordId1=" + lastId + "&keywordId2=" + nowId,
      );
      // console.log(res)
      if (res.code == 200) {
        this.$message.success("修改成功");
      } else {
        this.$message.error("修改失败");
      }
      //刷新关键词数据
      this.keywordGet();
    },
    //删除关键词
    async keywordDelv(id) {
      const { data: res } = await this.$http.delete(
          "/tbThirdPartyWangAdministrationKeyword/delv?i="+id
      );
      // console.log(res)
      if (res.code == 200) {
        this.$message.success("删除成功");
      } else {
        this.$message.error("删除失败");
      }
      //刷新关键词数据
      this.keywordGet();
    },


  }
}
</script>


<style scoped lang="less">
  .classesList{
    display: flex;
    margin: 30px;
    .classes{
      min-width: 200px;
      height: 50px;
      border-radius: 10px;
      background-color: #4bc2ff;
      margin-left: 10px;
      padding-left: 30px;
      overflow: hidden;
      display: flex;
      flex-wrap: wrap;
      .classesName{
        min-width: 100px;
        line-height: 50px;
        margin-right: 7px;
      }
      .update{
        width: 30px;
        height: 30px;
        background-color: #FFFFFF;
        color: #dd6161;
        margin-right: 12px;
        margin-top: 10px;
        text-align: center;
        line-height: 30px;
      }
    }
    .classesSet{
      width: 100px;
      height: 50px;
      border-radius: 10px;
      background-color: #4bc2ff;
      margin-left: 10px;
      text-align: center;
      line-height: 50px;
      font-size: 30px;
    }
  }

  .keywordList{
    margin: 50px;
    .keywordSet{
      width: 100px;
      height: 50px;
      border-radius: 10px;
      background-color: #8691c5;
      margin-left: 10px;
      text-align: center;
      line-height: 50px;
      font-size: 16px;
    }
    .len{
      margin-left: 10px;
      margin-top: 10px;
    }
    .keywords{
      display: flex;
      padding-top: 15px;
      padding-left: 15px;
      .keyword{
        width: 300px;
        height: 50px;
        background-color: #98a4e1;
        line-height: 50px;
        text-align: center;
        margin-right: 15px;
        border-radius: 10px;
      }
      .Num{
        width: 120px;
        height: 50px;
        text-align: center;
        margin-left: 10px;
        margin-right: 10px;
        line-height: 50px;
      }
      .unify{
        width: 50px;
        height: 50px;
        background-color: #7ebee1;
        line-height: 50px;
        text-align: center;
        margin-right: 10px;
      }

    }

  }

</style>