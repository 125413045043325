
<template>
  <div>
<!--  类别  -->
    <div class="classesList">
      <div v-for="(item, index) in classesList" :key="index">
        <div class="classes" @click="handleClick(item)" :style="classesId === item.id ? 'color:#ffffff; background-color: #69caf4;' : ''">
          {{ item.name }}
        </div>
      </div>
    </div>
<!--  关键词  -->
    <div class="keywordList">
      <el-checkbox v-model="checkAll" @change="handleCheckAllChange" class="checkAll">全选</el-checkbox>
      <el-checkbox-group v-model="keywordList_select" @change="handleCheckedCitiesChange">
        <el-checkbox v-for="(item,index) in keywordList" :label="item" :key="index">{{item.keyword}}</el-checkbox>
      </el-checkbox-group>
      <div class="spantext_one">
        <span class="spantext" style="color: #34a4f4">小程序总执行的数量：{{sum}}</span>
        <span class="spantext" style="color: #34a4f4">关键词总执行的数量：{{keywordListSum}}</span>
        <span class="spantext" style="color: #af7a34">小程序已执行的数量：{{messages.length}}</span>
        <span class="spantext" style="color: #5daf34">小程序成功的数量：{{succeedCount}}</span>
        <span class="spantext" style="color: #ff0000">小程序失败的数量：{{messages.length - succeedCount}}</span>
      </div>

        执行间隔时间至少：
        <el-input  type="number" style="width: 100px"  placeholder="默认10"   min="0"   v-model="sleepLeastTime"></el-input>
        秒 至多
        <el-input  type="number" style="width: 100px"  placeholder="默认60"   min="0"   max="1000"   v-model="sleepMostTime"></el-input>

        <div style="margin-top: 20px">
        <el-button class="but" type="primary" plain :loading="useDeployLoading" @click="multiApply(1)" >{{ useDeployLoading? '加载中' : '执行选择的类别' }}</el-button>
        <el-button class="but" type="warning" plain :loading="useDeployLoading" @click="multiApply(2)">{{ useDeployLoading? '加载中' : '执行选择的第三方' }}</el-button>
        <el-button class="but" type="danger" plain @click="ceaseTask()">强制终止执行</el-button>
        <el-button class="but" type="info" plain @click="dialogVisible = true">查看执行的全部结果</el-button>
        <el-dialog
            title="查看执行的全部结果"
            :visible.sync="dialogVisible"
            width="80%"
            append-to-body>
          <exhibitionMessagesTheSecond :messages="messages"></exhibitionMessagesTheSecond>
        </el-dialog>
        <el-button style="background-color: #6c6c6c; color: #ffffff" plain @click="getTask">刷新↕获取上一次执行的结果</el-button>
      </div>
    </div>

  </div>
</template>

<script>
import exhibitionMessagesTheSecond from "../gatherInformation/exhibitionMessagesTheSecond.vue";
let intervalId = null

export default {
  components: {exhibitionMessagesTheSecond},
  props: [
      /**
       * 1. 选中的类别
       * 2. 选中的第三方
       * 3. 第三方类型的 区分未备案/已备案
       * 4. redisKey（执行进度+历史记录）
       * 5. 锁（终止）
       */
      "checkedCities",
      "dimensionArr",
      "appletTypeIcpStatusList",
      "multiapplyRedisKey",
      "multiapplyLock"
  ],
  data() {
    return {
      /**
       * 类别
       */
      classesList: [], //所有的类别
      classesId: 0, //选中/当前显示的类别
      /**
       * 关键词
       */
      keywordList: [], //某个类别下的所有关键词
      keywordList_select: [], //选中的关键词
      /**
       * 执行批量 & 执行结果
       */
      redisKey: null,
      sum: 0,
      keywordListSum: 0,
      succeedCount: 0,
      messages: [],
      /**
       * 组件
       */
      //多选项
      checkAll: false,
      //弹出框
      dialogVisible: false,
      //加载中
      useDeployLoading: false,
      // 执行时间间隔
      sleepLeastTime:null, // 最短间隔
      sleepMostTime:null, // 最长间隔
    }
  },
  created() {
    this.classesGet()
        .then(this.keywordGet);
  },
  methods: {
    /**
     * 类别
     */
    //类别切换
    handleClick(item) {
      // console.log(item);
      this.classesId = item.id;
      this.keywordGet();
      this.checkAll = false;
    },
    //查询所有类别
    async classesGet() {
      const { data: res } = await this.$http.get(
          "/tbThirdPartyWangAdministrationKeywordClasses/getv"
      );
      // console.log(res)
      if (res.code == 200) {
        this.classesList = res.message;
        this.classesId = this.classesList[0].id;
      }
    },

    /**
     * 关键词
     */
    //根据类别id，查询对应的关键词
    async keywordGet() {
      const { data: res } = await this.$http.get(
          "/tbThirdPartyWangAdministrationKeyword/getIdv?classesId=" + this.classesId
      );
      // console.log(res)
      if (res.code == 200) {
        this.keywordList = res.message;
      }
    },

    /**
     * 批量执行
     */
    //执行批量小程序关键词
    async multiApply(is) {
      //判断是否有选择 类别或第三方
      let batchClassIdList = this.checkedCities.map(city => city.id); //选中的类别
      let appletTypeListList = this.dimensionArr.map(city => city.id); //选中的第三方
      if (is === 1 && batchClassIdList.length < 1) {
        this.$message.error("请选择类别");
        this.useDeployLoading = false
        return
      }
      if (is === 2 && appletTypeListList.length < 1) {
        this.$message.error("请选择第三方");
        this.useDeployLoading = false
        return
      }
      // 检查执行时间间隔
      if(!this.checkSleepTime()){
          this.useDeployLoadingB = false; //解除按钮等待状态
          return ;
      }
      //判断是否有选择 关键词
      if (this.keywordList_select.length < 1) {
        this.$message.error("请选择关键词");
        this.useDeployLoading = false
        return
      }
      //初始数据
      this.useDeployLoading = true
      this.sum = 0;
      this.succeedCount = 0;
      this.messages = [];

      //执行
      let appletTypeIcpStatus = this.checkIcpStatus2(this.appletTypeIcpStatusList)
      let multiAppletTypeOrBatchClassIdVo = {
        batchClassIdList: is === 1? batchClassIdList : null,
        appletTypeList: is === 2? appletTypeListList : null,
        appletTypeIcpStatus: appletTypeIcpStatus,
        keyWordsList: this.keywordList_select,
        sleepLeastTime: this.sleepLeastTime, // 最短间隔
        sleepMostTime: this.sleepMostTime, // 最长间隔
      }
      const { data: res } = await this.$http.post(
          "/tb-third-party-small-routine-keyword/multiApply",
          multiAppletTypeOrBatchClassIdVo
      );
      if (200 === res.code) {
        this.$message.warning("「批量小程序关键词」已提交，等待审核结果");
      } else {
        this.$message.error("「批量小程序关键词」提交失败：" + res.message);
        this.useDeployLoading = false
      }
      //记录
      this.redisKey = res.message?.redisKey;
      this.sum = res.message?.taskSum;
      this.keywordListSum = this.sum * this.keywordList_select.length;

      //查询进度
      this.startTaskProgress();
    },
    //轮询查 异步任务的进度
    async startTaskProgress() {
      intervalId = setInterval(async () => {
        const { data: res } = await this.$http.get(
            "/redisTasksBufferController/getTask?taskId=" + this.redisKey
        );
        console.log("执行进度：", res.message)
        if (res.message != null) {
          //小程序总任务数
          this.sum = res.message.taskSum? res.message.taskSum : '';
          //关键词总任务数
          this.keywordListSum = res.message.keywordListSum? res.message.keywordListSum : '';
          //已执行的结果列表
          this.messages = res.message?.multiAuditApplySbuMsgVoList? res.message?.multiAuditApplySbuMsgVoList : [];
          //成功数
          this.succeedCount = this.messages.filter(function(message) {
            return message.operateMessage === "成功";
          }).length;
        }
        //通过查询锁是否被删除，来决定任务进度是否完成或终止
        if (await this.selectLock()) {
          //解除按钮等待状态
          this.useDeployLoading = false;
          //清除定时器
          clearInterval(intervalId);
          //触发父组件
          this.$emit('updateGetList');
          this.$message.success("「批量小程序关键词」执行完成");
        }
      }, 5000); // 每5秒查询一次
    },
    //查询历史记录
    async getTask() {
      const { data: res } = await this.$http.get(
          "/redisTasksBufferController/getTask?taskId=" + this.multiapplyRedisKey
      );
      console.log(res)
      if (res.message != null) {
        //小程序总任务数
        this.sum = res.message.taskSum? res.message.taskSum : '';
        //关键词总任务数
        this.keywordListSum = res.message.keywordListSum? res.message.keywordListSum : '';
        //已执行的结果列表
        this.messages = res.message?.multiAuditApplySbuMsgVoList? res.message?.multiAuditApplySbuMsgVoList : [];
        //成功数
        this.succeedCount = this.messages.filter(function(message) {
          return message.operateMessage === "成功";
        }).length;
      }
    },
    //强制终止执行
    async ceaseTask() {
      const { data: res } = await this.$http.get(
          "/redisTasksBufferController/ceaseTask?lock=" + this.multiapplyLock
      );
      console.log("终止res", res);
      //解除按钮等待状态
      this.useDeployLoading = false;
      //清除定时器
      clearInterval(intervalId);
      //触发父组件
      this.$emit('updateGetList');
      this.$message.success("强制终止执行成功");
    },
    //查询锁是否被删除
    async selectLock() {
      const { data: res } = await this.$http.get(
          "/redisTasksBufferController/selectLock?lock=" + this.multiapplyLock
      );
      // console.log("查询锁是否被删除", res);
      return res.message;
    },


    /**
     * 组件
     */
    //多选项
    handleCheckAllChange(val) {
      this.keywordList_select = val ? this.keywordList : [];
    },
    handleCheckedCitiesChange(value) {
      let checkedCount = value.length;
      this.checkAll = checkedCount === this.keywordList.length;
    },
    //获取 第三方类型的 区分未备案/已备案 =》 返回的是 icpStatus 值（NOT_REGISTER、REGISTER_SUCCESS）
    checkIcpStatus2(appletTypeIcpStatusList) {
      // 检查是否所有元素的checked属性都为true
      if (appletTypeIcpStatusList.every(item => item.checked)) {
        return null;
      }
      // 找到第一个checked为true的元素并返回其icpStatus值
      const found = appletTypeIcpStatusList.find(item => item.checked);
      return found ? found.icpStatus : null;
    },
    /**
    * 检查执行时间间隔，也就是检查 sleepLeastTime 和 sleepMostTime 是否合法 <br>
    * @returns {boolean} 返回 true 表示合法，返回 false 表示不合法
    */
    checkSleepTime() {
      let isSleepTimeLessThanZero = (this.sleepLeastTime && this.sleepLeastTime < 0)
          || (this.sleepMostTime && this.sleepMostTime < 0);
      if(isSleepTimeLessThanZero){
          this.$message.error('最短间隔不能小于0');
          this.sleepLeastTime = null;
          return false;
      }
      if (this.sleepLeastTime && this.sleepMostTime &&this.sleepLeastTime > this.sleepMostTime) {
          this.$message.error('最短间隔不能大于最长间隔');
          this.sleepMostTime = null;
          this.sleepLeastTime = null;
          return false;
      }
      return true;
    }
  }
}


</script>


<style scoped lang="less">
    .classesList{
      display: flex;
      .classes{
        min-width: 120px;
        height: 35px;
        border-radius: 10px;
        background-color: #abd5e8;
        margin-left: 10px;
        flex-wrap: wrap;
        line-height: 35px;
        text-align: center;
        font-size: 16px;
      }
    }
    .keywordList{
      margin-top: 20px;
      margin-left: 10px;
      .checkAll{
        margin-bottom: 10px;
      }
      .spantext_one{
        margin-top: 30px;
        .spantext{
          font-size: 20px;
          margin-right: 50px;
        }
      }
      .but{
        margin-right: 10px;
      }

    }
</style>