
<template>
  <div>
    <div style="margin-top: 30px">刷新描述，非必选 ------>></div>
    <div>
      <el-button	@click="optionModule = ''" type="info" style="margin-right: 5px">清除选择</el-button>
      <el-radio-group v-model="optionModule" v-for="(item,index) in optionModuleList" :key="index" >
        <el-radio-button :label="item.groupName" style="margin: 10px"></el-radio-button>
      </el-radio-group>
    </div>
    <div style="margin-top: 30px">类目，非必选 ------>></div>
    <div style="margin-top: 10px">
      类目1：
      <el-cascader
          v-model="value1"
          :options="optionsList"
          :props="{ expandTrigger: 'hover' }"></el-cascader>
      <el-button size="mini" @click="value1 = null">取消</el-button>
    </div>
    <div style="margin-top: 10px">
      类目2：
      <el-cascader
          v-model="value2"
          :options="optionsList"
          :props="{ expandTrigger: 'hover' }"></el-cascader>
      <el-button size="mini" @click="value2 = null">取消</el-button>
    </div>
    <div style="margin-top: 10px">
      类目3：
      <el-cascader
          v-model="value3"
          :options="optionsList"
          :props="{ expandTrigger: 'hover' }"></el-cascader>
      <el-button size="mini" @click="value3 = null">取消</el-button>
    </div>
    <div style="margin-top: 20px">可选择某个模板的类目：</div>
    <div class="TbThirdPartyTemplateList">
      <div class="TbThirdPartyTemplate" v-for="(item,index) in TbThirdPartyTemplateList" :key="index" @click="category(item)">
        {{ item.name }}
      </div>
    </div>

    <div style="margin-top: 30px">
      <h3 style="color: #00a8ff">总任务数：{{appletAmount1}}</h3>
      <h3 style="color: #af7a34">已执行的数量：{{count}}</h3>
      <h3 style="color: #5daf34">成功的数量：{{succeedCount}}</h3>
      <h3 style="color: #af3434">失败数：{{messages.length - succeedCount}}</h3>
    </div>

    执行间隔时间至少：
    <el-input  type="number" style="width: 100px"  placeholder="默认10"   min="0"   v-model="sleepLeastTime"></el-input>
    秒 至多
    <el-input  type="number" style="width: 100px"  placeholder="默认60"   min="0"   max="1000"   v-model="sleepMostTime"></el-input>
    <br>
    <el-button type="primary" plain @click="submit_multi_auditApply(1)" :loading="useDeployLoadingB" class="but">
      {{ useDeployLoadingB? '加载中' : '执行选择的类别' }}</el-button>
    <el-button type="warning" plain @click="submit_multi_auditApply(2)" :loading="useDeployLoadingB" class="but">
      {{ useDeployLoadingB? '加载中' : '执行选择的第三方' }}</el-button>
    <el-button class="but" type="danger" plain @click="ceaseTask()">强制终止执行</el-button>
    <el-button class="but" type="info" plain @click="dialogVisible = true">查看执行的全部结果</el-button>
    <el-dialog
        title="查看执行的全部结果"
        :visible.sync="dialogVisible"
        width="80%"
        append-to-body>
      <exhibitionMessagesTheSecond :messages="messages"></exhibitionMessagesTheSecond>
    </el-dialog>
    <el-button style="background-color: #6c6c6c; color: #ffffff" plain @click="getTask">刷新↕获取上一次执行的结果</el-button>
  </div>
</template>

<script>
import exhibitionMessagesTheSecond from "../gatherInformation/exhibitionMessagesTheSecond.vue";
import { queryVoByApplicationsId } from "@/services/thirdParty/queryVoByApplicationsId";


let intervalId1 = null

export default {
  components: {
    exhibitionMessagesTheSecond
  },
  props: [
      /**
       * 1. 选中的类别
       * 2. 选中的第三方
       * 3. 第三方类型的 区分未备案/已备案
       * 4. 上一次历史记录的redisKey
       */
      "checkedCities",
      "dimensionArr",
      "appletTypeIcpStatusList",
      "submitMultiAuditApplyRedisKey",
      "submitMultiAuditApplyLock"
  ],
  data() {
    return {
      //全部的选项模块
      optionModuleList:[],
      //选中的选项模块.groupName
      optionModule: "",
      //按钮配置
      useDeployLoadingB: false,
      //总任务数
      appletAmount1: 0,
      //已完成数
      count: 0,
      //成功数
      succeedCount: 0,
      //异步任务的标识
      redisKey: null,

      //格式化后的 获取全部类目
      optionsList: [],
      //选择的类目数组-1
      value1: null,
      //选择的类目数组-2
      value2: null,
      //选择的类目数组-3
      value3: null,
      //（后端需要的参数）类目选项.miniCategoryIds
      miniCategoryIds: '',
      //所有模板的信息
      TbThirdPartyTemplateList: [],
      // 执行时间间隔
      sleepLeastTime:null, // 最短间隔
      sleepMostTime:null, // 最长间隔
      //记录执行的结果
      messages: [],
      //弹出对话框
      dialogVisible: false,
    }
  },
  created() {
    this.getOptionModule();
    this.getMiniCategoryIds();
    this.getCategory();
  },
  methods: {

    //获取 第三方类型的 区分未备案/已备案 =》 返回的是 icpStatus 值（NOT_REGISTER、REGISTER_SUCCESS）
    checkIcpStatus2(appletTypeIcpStatusList) {
      // 检查是否所有元素的checked属性都为true
      if (appletTypeIcpStatusList.every(item => item.checked)) {
        this.appletTypeIcpStatus = null;
        // console.log("appletTypeIcpStatus", this.appletTypeIcpStatus)
        return null;
      }
      // 找到第一个checked为true的元素并返回其icpStatus值
      const found = appletTypeIcpStatusList.find(item => item.checked);
      this.appletTypeIcpStatus = found ? found.icpStatus : null;
      // console.log("appletTypeIcpStatus", this.appletTypeIcpStatus)
      return found ? found.icpStatus : null;
    },

    //获取所有模板的信息
    async getCategory() {
      // 执行接口请求
      const { data: res } = await this.$http(
          "/thirdPartyTemplate/getCategory",
      );
      if (res.code == "200") {
        // this.$message.success("获取成功");
        this.TbThirdPartyTemplateList = res.message;
      } else {
        this.$message.error(res.message);
      }
      console.log("全部模板：", this.TbThirdPartyTemplateList)
    },
    //选择某个模板的类目
    category(item) {
      // console.log(item.name + "模板的类目：",item.category)
      if (item.category) {
        var parts = item.category.split(';');
        // console.log("分割后：", parts)
        this.value1 = parts[0]? parts[0].split('_') : null;
        this.value2 = parts[1]? parts[1].split('_') : null;
        this.value3 = parts[2]? parts[2].split('_'): null;
      } else {
        this.value1 = null;
        this.value2 = null;
        this.value3 = null;
      }
    },
    //获取全部的选项模块
    async getOptionModule() {
      // 执行接口请求
      const { data: res } = await this.$http(
          "/tb-third-party-small-routine-audit-template/listDigest",
      );
      if (res.code == "200") {
        // this.$message.success("获取成功");
        this.optionModuleList = res.message;
      } else {
        this.$message.error(res.message);
      }
      // console.log("模块选择：", this.optionModuleList)
    },
    //获取全部的类目
    async getMiniCategoryIds() {
      let res = await queryVoByApplicationsId({
        id: "6bb3e94f7e25a00a2f984d839ac0085d",
      });
      // console.log("原始所有的类目：", res)

      //格式化：对象包含数组 转换为 数组包含数组
      let cascaderData  = this.convertDataToCascaderFormat(res.data);
      // console.log("格式化后的所有类目：", cascaderData)

      this.optionsList = cascaderData;
    },
    //格式化获取的全部类目
    convertDataToCascaderFormat(data) {
      return Object.keys(data).map(key => {
        const item = data[key];
        return {
          value: item.category_id,
          label: item.category_name,
          children: item.children && item.children.length > 0 ? this.convertDataToCascaderFormat(item.children) : undefined
        };
      });
    },

    //查询历史记录
    async getTask() {
      const { data: res } = await this.$http.get(
          "/redisTasksBufferController/getTask?taskId=" + this.submitMultiAuditApplyRedisKey
      );
      if (res.message != null) {
        //总任务数
        this.appletAmount1 = res.message.taskSum? res.message.taskSum : '';
        //进度
        this.count = res.message.auditedAccomplishCount? res.message.auditedAccomplishCount : '';
        //全部信息列表
        this.messages = res.message?.multiAuditApplySbuMsgVoList? res.message?.multiAuditApplySbuMsgVoList : [];
        //计算出成功数
        this.succeedCount = this.messages.filter(function(message) {
          return message.operateMessage === "成功";
        }).length;
      }
    },
    //执行提交审核
    async submit_multi_auditApply(is) {
      this.useDeployLoadingB = true; //按钮进入等待状态

      let batchClassIdList = this.checkedCities.map(city => city.id); //选中的类别
      let appletTypeListList = this.dimensionArr.map(city => city.id); //选中的第三方
      if (batchClassIdList.length < 1 && appletTypeListList.length < 1) {
        this.$message.error("请选择类别或第三方");
        this.useDeployLoadingB = false; //解除按钮等待状态
        return
      }
      // 检查执行时间间隔
      if(!this.checkSleepTime()){
          this.useDeployLoadingB = false; //解除按钮等待状态
          return ;
      }
      let appletTypeIcpStatus = this.checkIcpStatus2(this.appletTypeIcpStatusList)

      //处理选择的类目，拼接为，例如"XS1022_XS2204_XS3236;XS1001_XS2001_XS3001;XS1023_XS2184_XS3535;"
      if (this.value1) {
        // console.log("value1进来")
        this.miniCategoryIds += this.value1.map(value => value.toString()).join('_') + ";";
      }
      if (this.value2) {
        // console.log("value2进来")
        this.miniCategoryIds += this.value2.map(value => value.toString()).join('_') + ";";
      }
      if (this.value3) {
        // console.log("value3进来")
        this.miniCategoryIds += this.value3.map(value => value.toString()).join('_') + ";";
      }
      console.log("选择的类目：", this.miniCategoryIds)

      let multiAppletTypeOrBatchClassIdVo = {
        groupName: this.optionModule, //模块
        miniCategoryIds: this.miniCategoryIds, //类目
        batchClassIdList: is === 1? batchClassIdList : null,
        appletTypeList: is === 2? appletTypeListList : null,
        appletTypeIcpStatus: appletTypeIcpStatus,
        sleepLeastTime: this.sleepLeastTime, // 最短间隔
        sleepMostTime: this.sleepMostTime, // 最长间隔
      }

      //提交审核
      const { data: res } = await this.$http.post(
          "/thirdParty2/multiAppletTypeOrBatchClassIdAudit",
          multiAppletTypeOrBatchClassIdVo
      );
      if (200 === res.code) {
        this.$message.warning("「提交审核」已提交，等待审核结果");
      } else {
        this.$message.error("「提交审核」提交失败:" + res.message);
        this.useDeployLoadingB = false;
      }

      this.redisKey = res.message.redisKey;
      this.appletAmount1 = res.message.taskSum;

      //查询进度
      this.startTaskProgress1();
      // 重置类目选择
      this.miniCategoryIds = '';
    },
    //查询进度
    async startTaskProgress1() {
      //初始化数量
      this.count = 0;
      this.messages = []
      intervalId1 =  setInterval(async () => {
        //查询 查询批量审核的进度
        const { data: res } = await this.$http.get(
            "/redisTasksBufferController/getTask?taskId=" + this.redisKey
        );
        console.log("审核进度", res.message)

        if (res.message != null) {
          //进度
          this.count = res.message.auditedAccomplishCount? res.message.auditedAccomplishCount : '';
          //全部信息列表
          this.messages = res.message?.multiAuditApplySbuMsgVoList? res.message?.multiAuditApplySbuMsgVoList : [];
          //计算出成功数
          this.succeedCount = this.messages.filter(function(message) {
            return message.operateMessage === "成功";
          }).length;
        }

        //所有任务进度完成
        if (this.count == this.appletAmount1) {
          //解除按钮等待状态
          this.useDeployLoadingB = false;
          //清除定时器
          clearInterval(intervalId1);
          //触发父组件
          this.$emit('updateGetList');
          this.$message.success("「批量提交审核」执行完成");
        }
      }, 5000); // 每5秒查询一次
    },
    //强制终止执行
    async ceaseTask() {
      const { data: res } = await this.$http.get(
          "/redisTasksBufferController/ceaseTask?lock=" + this.submitMultiAuditApplyLock
      );
      console.log("终止res", res);

      //解除按钮等待状态
      this.useDeployLoadingB = false;
      //清除定时器
      clearInterval(intervalId1);
      //触发父组件
      this.$emit('updateGetList');
      this.$message.success("强制终止执行成功");
    },
  /**
   * 检查执行时间间隔，也就是检查 sleepLeastTime 和 sleepMostTime 是否合法 <br>
   * @returns {boolean} 返回 true 表示合法，返回 false 表示不合法
   */
    checkSleepTime() {
      let isSleepTimeLessThanZero = (this.sleepLeastTime && this.sleepLeastTime < 0)
            || (this.sleepMostTime && this.sleepMostTime < 0);
      if(isSleepTimeLessThanZero){
        this.$message.error('最短间隔不能小于0');
        this.sleepLeastTime = null;
        return false;
      }
      if (this.sleepLeastTime && this.sleepMostTime &&this.sleepLeastTime > this.sleepMostTime) {
        this.$message.error('最短间隔不能大于最长间隔');
        this.sleepMostTime = null;
        this.sleepLeastTime = null;
        return false;
      }
      return true;
    }
  }

}
</script>

<style scoped lang="less">
.but{
  margin-right: 10px;
}
.TbThirdPartyTemplateList{
  display: flex;
  flex-wrap: wrap;

  .TbThirdPartyTemplate{
    width: 100px;
    height: 30px;
    margin: 10px;
    border: 2px solid #000000;
    text-align: center;
    line-height: 30px;
  }
}

</style>