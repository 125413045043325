
<template>
  <div>
    <el-divider content-position="left">
      <span class="Span">类别选择</span>
    </el-divider>
    <div>
      <el-checkbox v-model="checkAll" @change="handleCheckAllChange">全选</el-checkbox>
      <el-button size="medium" type="primary" @click="switchover()" style="margin-left: 30px">{{ icpStatus }}</el-button>
      <div style="margin: 15px 0;"></div>
      <el-checkbox-group v-model="checkedCities" @change="handleCheckedCitiesChange">
        <el-checkbox v-for="city in allCategoryList" :label="city" :key="city.id" style="margin-left: 20px; margin-top: 10px; width: 150px">
          {{city.name}}</el-checkbox>
      </el-checkbox-group>
    </div>

    <div class="fg"></div>
    <el-divider content-position="left">
      <span class="Span">第三方选择</span>
    </el-divider>
    <div>
      <el-checkbox v-model="appletTypeIcpStatusList[0].checked">未备案</el-checkbox>
      <el-checkbox v-model="appletTypeIcpStatusList[1].checked">已备案</el-checkbox>

      <div style="margin: 15px 0;"></div>
      <el-checkbox v-model="checkAllType" @change="handleCheckAllChangeType">全选</el-checkbox>
      <div style="margin: 15px 0;"></div>
      <el-checkbox-group v-model="dimensionArr" @change="handleCheckedCitiesChangeType">
        <el-checkbox v-for="city in tbThirdPartySmallRoutineType" :label="city" :key="city.id" style="margin-left: 20px; margin-top: 10px; width: 150px">
          {{city.name}}</el-checkbox>
      </el-checkbox-group>
    </div>

    <div class="fg"></div>
    <el-divider content-position="left">
      <span class="SpanA">批量应用配置</span>
    </el-divider>
    <div>
      <updateBatchClassAppsMultiConfig
          :checkedCities="checkedCities"
          :dimensionArr="dimensionArr"
          :appletTypeIcpStatusList="appletTypeIcpStatusList"
          @updateGetList="updateGetList"
      ></updateBatchClassAppsMultiConfig>

    </div>

    <div class="fg"></div>
    <el-divider content-position="left">
      <span class="SpanA">批量上传版本</span>
    </el-divider>
    <div>
      <updateVersions
          :checkedCities="checkedCities"
          :dimensionArr="dimensionArr"
          :appletTypeIcpStatusList="appletTypeIcpStatusList"
          @updateGetList="updateGetList"
          :updateVersionsRedisKey="updateVersionsRedisKey"
          :updateVersionsLock="updateVersionsLock"
      ></updateVersions>

    </div>


    <div class="fg"></div>
    <el-divider content-position="left">
      <span class="SpanA">批量提交审核</span>
    </el-divider>
    <div>
      <submitMultiAuditApply
          :checkedCities="checkedCities"
          :dimensionArr="dimensionArr"
          :appletTypeIcpStatusList="appletTypeIcpStatusList"
          @updateGetList="updateGetList"
          :submitMultiAuditApplyRedisKey="submitMultiAuditApplyRedisKey"
          :submitMultiAuditApplyLock="submitMultiAuditApplyLock"
      ></submitMultiAuditApply>
    </div>

<!--    <div class="fg"></div>-->
<!--    <el-divider content-position="left">-->
<!--      <span class="SpanA">批量驳回退回开发</span>-->
<!--    </el-divider>-->
<!--    <div>-->
<!--      <el-button type="primary" @click="multiAuditedCancel(1)" :loading="useDeployLoadingD">-->
<!--        {{ useDeployLoadingD? '加载中' : '执行选择的类别' }}</el-button>-->
<!--      <el-button type="warning" plain @click="multiAuditedCancel(2)" :loading="useDeployLoadingD">-->
<!--        {{ useDeployLoadingD? '加载中' : '执行选择的第三方' }}</el-button>-->
<!--      <div style="margin-top: 30px">-->
<!--        <el-progress :text-inside="true" :stroke-width="22" status="success" :percentage="percentage4"></el-progress>-->
<!--      </div>-->
<!--    </div>-->

    <div class="fg"></div>
    <el-divider content-position="left">
      <span class="SpanA">批量提交服务提报</span>
    </el-divider>
    <div>
      <batchServicePresentation
          :checkedCities="checkedCities"
          :dimensionArr="dimensionArr"
          :appletTypeIcpStatusList="appletTypeIcpStatusList"
          :multiServiceApplyRedisKey="multiServiceApplyRedisKey"
          :multiServiceApplyLock = "multiServiceApplyLock"
      ></batchServicePresentation>
    </div>


    <div class="fg"></div>
    <el-divider content-position="left">
      <span class="SpanA">批量删除服务提报</span>
    </el-divider>
    <div>
      <batchDeleteService :multiDeleteServiceApplyRedisKey="multiDeleteServiceApplyRedisKey" :multiDeleteServiceApplyLock="multiDeleteServiceApplyLock"></batchDeleteService>
    </div>


    <div class="fg"></div>
    <el-divider content-position="left">
      <span class="SpanA">批量小程序关键词</span>
    </el-divider>
    <div>
      <multiApply
          :checkedCities="checkedCities"
          :dimensionArr="dimensionArr"
          :appletTypeIcpStatusList="appletTypeIcpStatusList"
          :multiapplyRedisKey="multiapplyRedisKey"
          :multiapplyLock = "multiapplyLock"
      ></multiApply>
    </div>


    <div class="fg"></div>
    <el-divider content-position="left">
      <span class="SpanA">批量删除小程序被驳回的关键词</span>
    </el-divider>
    <div>
      <multiDelete
          :checkedCities="checkedCities"
          :dimensionArr="dimensionArr"
          :appletTypeIcpStatusList="appletTypeIcpStatusList"
          :multideleteRedisKey="multideleteRedisKey"
          :multideleteLock = "multideleteLock"
      ></multiDelete>
    </div>

  </div>
</template>

<script>
import batchServicePresentation from './batchConfiguration/batchServicePresentation.vue'
import batchDeleteService from './batchConfiguration/batchDeleteService.vue'
import updateBatchClassAppsMultiConfig from './batchConfiguration/updateBatchClassAppsMultiConfig.vue'
import updateVersions from './batchConfiguration/updateVersions.vue'
import submitMultiAuditApply from './batchConfiguration/submitMultiAuditApply.vue'
import multiApply from './batchConfiguration/multiApply.vue'
import multiDelete from './batchConfiguration/multiDelete.vue'

let intervalId4 = null

export default {
  components:{
    updateBatchClassAppsMultiConfig,
    updateVersions,
    submitMultiAuditApply,
    batchServicePresentation,
    batchDeleteService,
    multiApply,
    multiDelete
  },
  props: [
      'tbThirdPartySmallRoutineType', //所有的第三方
      'appletType', //第三方类型 Type
  ],
  data() {
    return {
      //（选项）类别、第三方
      checkAll: true,
      allCategoryList: [], //显示所有类别
      icpStatus: "已备案",
      checkedCities: [], //选中的类别
      checkAllType: false,
      dimensionArr: [], //选中的第三方
      appletTypeIcpStatusList: [ //第三方类型的 区分未备案/已备案
        {
          name: "未备案",
          icpStatus: "NOT_REGISTER",
          checked: true
        },
        {
          name: "已备案",
          icpStatus: "REGISTER_SUCCESS",
          checked: true
        }
      ],

      //各个批量执行的 上一次历史记录的redisKey
      //批量版本升级
      updateVersionsRedisKey: '',
      //批量提交审核
      submitMultiAuditApplyRedisKey: '',
      //批量提交服务提报
      multiServiceApplyRedisKey: '',
      //批量删除服务提报
      multiDeleteServiceApplyRedisKey: '',
      //批量小程序关键词
      multiapplyRedisKey: '',
      //批量删除小程序的关键词
      multideleteRedisKey: '',

      //各个批量执行的 锁
      //批量版本升级
      updateVersionsLock: '',
      //批量提交审核
      submitMultiAuditApplyLock: '',
      //批量提交服务提报
      multiServiceApplyLock: '',
      //批量删除服务提报
      multiDeleteServiceApplyLock: '',
      //批量小程序关键词
      multiapplyLock: '',
      //批量删除小程序的关键词
      multideleteLock: '',

      //批量驳回退回开发
      useDeployLoadingD: false,
      appletAmount4: 0,
      percentage4: 0,
    }
  },
  created() {
    this.getBatchClassServiceList()
    this.preserveHistoryrecord()
  },
  methods: {
    /**
     * 类别选择
     */
    //按钮切换：已备案/未备案
    switchover() {
      this.icpStatus = this.icpStatus === "已备案"? "未备案" : "已备案";
      this.getBatchClassServiceList();
    },
    //获取全部的类别（区分已备案和未备案）
    async getBatchClassServiceList() {
      // console.log("this.appletType", this.appletType)
      const { data: res } = await this.$http.get(
          "/tb-third-party-wang-batch-class/getBatchClassServiceList?appletType=" + this.appletType + "&icpStatus=" + this.icpStatus
      )
      if(res.code == 200){
        this.checkedCities = res.message;
        this.allCategoryList = res.message;
      }else{
        this.$message.error("获取类别失败");
      }
    },
    //获取 第三方类型的 区分未备案/已备案 =》 返回的是 name 值（"已备案/未备案"）
    checkIcpStatus(appletTypeIcpStatusList) {
      // 检查是否所有元素的checked属性都为true
      if (appletTypeIcpStatusList.every(item => item.checked)) {
        return null;
      }
      // 找到第一个checked为true的元素并返回其icpStatus值
      const found = appletTypeIcpStatusList.find(item => item.checked);
      return found ? found.name : null;
    },
    //获取 第三方类型的 区分未备案/已备案 =》 返回的是 icpStatus 值（NOT_REGISTER、REGISTER_SUCCESS）
    checkIcpStatus2(appletTypeIcpStatusList) {
      // 检查是否所有元素的checked属性都为true
      if (appletTypeIcpStatusList.every(item => item.checked)) {
        this.appletTypeIcpStatus = null;
        console.log("appletTypeIcpStatus", this.appletTypeIcpStatus)
        return null;
      }
      // 找到第一个checked为true的元素并返回其icpStatus值
      const found = appletTypeIcpStatusList.find(item => item.checked);
      this.appletTypeIcpStatus = found ? found.icpStatus : null;
      console.log("appletTypeIcpStatus", this.appletTypeIcpStatus)
      return found ? found.icpStatus : null;
    },
    handleCheckAllChange(val) {
      this.checkedCities = val ? this.allCategoryList : [];
    },
    handleCheckedCitiesChange(value) {
      let checkedCount = value.length;
      this.checkAll = checkedCount === this.allCategoryList.length;
    },
    handleCheckAllChangeType(val) {
      this.dimensionArr = val ? this.tbThirdPartySmallRoutineType : [];
    },
    handleCheckedCitiesChangeType(value) {
      let checkedCount = value.length;
      this.checkAllType = checkedCount === this.tbThirdPartySmallRoutineType.length;
    },

    //子触发该函数
    updateGetList() {
      //触发父组件（config_dialog.vue） -> 更新小程序列表的数据
      this.$emit('getList');
    },

    /**
     * 批量驳回退回开发
     */
    //执行退回开发
    async multiAuditedCancel(is) {
      this.useDeployLoadingD = true

      let batchClassIdList = this.checkedCities.map(city => city.id); //选中的类别
      let appletTypeListList = this.dimensionArr.map(city => city.id); //选中的第三方
      if (is === 1 && batchClassIdList.length < 1) {
        this.$message.error("请选择类别");
        this.useDeployLoadingD = false
        return
      }
      if (is === 2 && appletTypeListList.length < 1) {
        this.$message.error("请选择第三方");
        this.useDeployLoadingD = false
        return
      }
      let appletTypeIcpStatus = this.checkIcpStatus2(this.appletTypeIcpStatusList)

      // console.log("选中的", appletTypeListList)
      let multiAppletTypeOrBatchClassIdVo = {
        batchClassIdList: is === 1? batchClassIdList : null,
        appletTypeList: is === 2? appletTypeListList : null,
        appletTypeIcpStatus: appletTypeIcpStatus
      }

      //计算要执行多少小程序
      await this.countAppletAmount4(multiAppletTypeOrBatchClassIdVo);
      //启动进度条
      this.startTaskProgress4();

      const { data: res } = await this.$http.post(
          "/thirdParty2/multiAuditedCancel",
          multiAppletTypeOrBatchClassIdVo
      );
      if (200 === res.code) {
        this.$message.success("「退回开发」执行完成");
      } else {
        this.$message.error("「退回开发」执行失败:" + res.message);
      }

      this.useDeployLoadingD = false

      //任务真正的完成了
      this.percentage4 = 100;
      clearInterval(intervalId4); // 所有任务完成后清除定时器

      //触发父组件 -> 更新小程序列表的数据
      this.$emit('getList');
    },
    //获取 计算要执行多少小程序
    async countAppletAmount4(multiAppletTypeOrBatchClassIdVo) {
      //后台计算有多少小程序
      const { data: res } = await this.$http.post(
          "/tb-third-party-wang-batch-class/countAppletAmount",
          multiAppletTypeOrBatchClassIdVo
      );
      if (200 === res.code) {
        this.appletAmount4 = res.message;
      } else {
        this.$message.error("计算进度条进度失败:" + res.message);
      }
    },
    //进度条
    startTaskProgress4() {
      this.percentage4 = 1;
      intervalId4 = setInterval(() => {
        if (this.percentage4 < 100) {
          this.percentage4 += Math.round(100 / this.appletAmount4);
        } else {
          // 所有任务完成后清除定时器
          clearInterval(intervalId4);
        }
      }, 300); // 每300毫秒更新一次
    },

    //获取 各个批量执行的 上一次历史记录的redisKey 和 锁
    async preserveHistoryrecord() {
      const { data: res } = await this.$http.get(
          "/redisTasksBufferController/preserveHistoryrecord"
      );
      console.log("获取每个批量保存的异步批量标识和锁", res);
      this.updateVersionsRedisKey = res.message.updateVersionsRedisKey
      this.submitMultiAuditApplyRedisKey = res.message.submitMultiAuditApplyRedisKey
      this.multiServiceApplyRedisKey = res.message.multiServiceApplyRedisKey
      this.multiDeleteServiceApplyRedisKey = res.message.multiDeleteServiceApplyRedisKey
      this.multiapplyRedisKey = res.message.multiapplyRedisKey
      this.multideleteRedisKey = res.message.multideleteRedisKey

      this.updateVersionsLock = res.message.updateVersionsLock
      this.submitMultiAuditApplyLock = res.message.submitMultiAuditApplyLock
      this.multiServiceApplyLock = res.message.multiServiceApplyLock
      this.multiDeleteServiceApplyLock = res.message.multiDeleteServiceApplyLock
      this.multiapplyLock = res.message.multiapplyLock
      this.multideleteLock = res.message.multideleteLock
    },

  }
}

</script>

<style scoped lang="less">
.Span{
  color: #ff8b8b;
  font-size: 20px;
  font-weight: bold;
}
.SpanA{
  color: #938bff;
  font-size: 20px;
  font-weight: bold;
}
.fg{
  margin-top: 100px;
}
</style>