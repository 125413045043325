
<template>
  <div>
<!--  关键词  -->
    <div class="keywordList">
      <div class="spantext_one">
        <span class="spantext" style="color: #34a4f4">小程序总执行的数量：{{sum}}</span>
        <span class="spantext" style="color: #af7a34">小程序已执行的数量：{{xcxSum}}</span>
        <span class="spantext" style="color: #af7a34">关键词已执行的数量：{{messages.length}}</span>
        <span class="spantext" style="color: #5daf34">关键词成功的数量：{{succeedCount}}</span>
        <span class="spantext" style="color: #ff0000">关键词失败的数量：{{messages.length - succeedCount}}</span>
      </div>
      <div style="margin-top: 20px">
        <el-button class="but" type="primary" plain :loading="useDeployLoading" @click="multiApply(1)" >{{ useDeployLoading? '加载中' : '执行选择的类别' }}</el-button>
        <el-button class="but" type="warning" plain :loading="useDeployLoading" @click="multiApply(2)">{{ useDeployLoading? '加载中' : '执行选择的第三方' }}</el-button>
        <el-button class="but" type="danger" plain @click="ceaseTask()">强制终止执行</el-button>
        <el-button class="but" type="info" plain @click="dialogVisible = true">查看执行的全部结果</el-button>
        <el-dialog
            title="查看执行的全部结果"
            :visible.sync="dialogVisible"
            width="80%"
            append-to-body>
          <exhibitionMessagesTheSecond :messages="messages"></exhibitionMessagesTheSecond>
        </el-dialog>
        <el-button style="background-color: #6c6c6c; color: #ffffff" plain @click="getTask">刷新↕获取上一次执行的结果</el-button>
      </div>
    </div>

  </div>
</template>

<script>
import exhibitionMessagesTheSecond from "../gatherInformation/exhibitionMessagesTheSecond.vue";
let intervalId = null

export default {
  components: {exhibitionMessagesTheSecond},
  props: [
      /**
       * 1. 选中的类别
       * 2. 选中的第三方
       * 3. 第三方类型的 区分未备案/已备案
       * 4. redisKey（执行进度+历史记录）
       * 5. 锁（终止）
       */
      "checkedCities",
      "dimensionArr",
      "appletTypeIcpStatusList",
      "multideleteRedisKey",
      "multideleteLock"
  ],
  data() {
    return {
      /**
       * 执行批量 & 执行结果
       */
      redisKey: null,
      sum: 0,
      xcxSum: 0,
      succeedCount: 0,
      messages: [],
      /**
       * 组件
       */
      //弹出框
      dialogVisible: false,
      //加载中
      useDeployLoading: false,
    }
  },
  created() {

  },
  methods: {
    /**
     * 批量执行
     */
    //执行批量删除小程序关键词
    async multiApply(is) {
      //判断是否有选择 类别或第三方
      let batchClassIdList = this.checkedCities.map(city => city.id); //选中的类别
      let appletTypeListList = this.dimensionArr.map(city => city.id); //选中的第三方
      if (is === 1 && batchClassIdList.length < 1) {
        this.$message.error("请选择类别");
        this.useDeployLoading = false
        return
      }
      if (is === 2 && appletTypeListList.length < 1) {
        this.$message.error("请选择第三方");
        this.useDeployLoading = false
        return
      }
      //初始数据
      this.useDeployLoading = true
      this.sum = 0;
      this.succeedCount = 0;
      this.messages = [];

      //执行
      let appletTypeIcpStatus = this.checkIcpStatus2(this.appletTypeIcpStatusList)
      let multiAppletTypeOrBatchClassIdVo = {
        batchClassIdList: is === 1? batchClassIdList : null,
        appletTypeList: is === 2? appletTypeListList : null,
        appletTypeIcpStatus: appletTypeIcpStatus,
      }
      const { data: res } = await this.$http.post(
          "/tb-third-party-small-routine-keyword/multiDelete",
          multiAppletTypeOrBatchClassIdVo
      );
      if (200 === res.code) {
        this.$message.warning("「批量删除小程序关键词」已提交，等待审核结果");
      } else {
        this.$message.error("「批量删除小程序关键词」提交失败：" + res.message);
        this.useDeployLoading = false
      }
      //记录
      this.redisKey = res.message?.redisKey;
      this.sum = res.message?.taskSum;

      //查询进度
      this.startTaskProgress();
    },
    //轮询查 异步任务的进度
    async startTaskProgress() {
      intervalId = setInterval(async () => {
        const { data: res } = await this.$http.get(
            "/redisTasksBufferController/getTask?taskId=" + this.redisKey
        );
        console.log("执行进度：", res.message)
        if (res.message != null) {
          //小程序总任务数
          this.sum = res.message.taskSum? res.message.taskSum : '';
          //已执行的结果列表
          this.messages = res.message?.multiAuditApplySbuMsgVoList? res.message?.multiAuditApplySbuMsgVoList : [];
          //已执行的小程序数
          const uniqueApplds = new Set(this.messages.map(msg => msg.appId));
          this.xcxSum = uniqueApplds.size;
          //成功数
          this.succeedCount = this.messages.filter(function(message) {
            return message.operateMessage === "成功" || message.operateMessage === "非驳回";
          }).length;
        }
        // console.log("进来了？", await this.selectLock())
        // 通过查询锁是否被删除，来决定任务进度是否完成或终止
        if (await this.selectLock()) {
          //解除按钮等待状态
          this.useDeployLoading = false;
          //清除定时器
          clearInterval(intervalId);
          //触发父组件
          this.$emit('updateGetList');
          this.$message.success("「批量删除小程序关键词」执行完成");
        }
      }, 5000); // 每5秒查询一次
    },
    //查询历史记录
    async getTask() {
      const { data: res } = await this.$http.get(
          "/redisTasksBufferController/getTask?taskId=" + this.multideleteRedisKey
      );
      console.log(res)
      if (res.message != null) {
        //总任务数
        this.sum = res.message.taskSum? res.message.taskSum : '';
        //已执行的结果列表
        this.messages = res.message?.multiAuditApplySbuMsgVoList? res.message?.multiAuditApplySbuMsgVoList : [];
        //已执行的小程序数
        const uniqueApplds = new Set(this.messages.map(msg => msg.appId));
        this.xcxSum = uniqueApplds.size;
        //成功数
        this.succeedCount = this.messages.filter(function(message) {
          return message.operateMessage === "成功" || message.operateMessage === "非驳回";
        }).length;
      }
    },
    //强制终止执行
    async ceaseTask() {
      const { data: res } = await this.$http.get(
          "/redisTasksBufferController/ceaseTask?lock=" + this.multideleteLock
      );
      console.log("终止res", res);
      //解除按钮等待状态
      this.useDeployLoading = false;
      //清除定时器
      clearInterval(intervalId);
      //触发父组件
      this.$emit('updateGetList');
      this.$message.success("强制终止执行成功");
    },
    //查询锁是否被删除
    async selectLock() {
      const { data: res } = await this.$http.get(
          "/redisTasksBufferController/selectLock?lock=" + this.multideleteLock
      );
      return res.message;
    },

    /**
     * 组件
     */
    //获取 第三方类型的 区分未备案/已备案 =》 返回的是 icpStatus 值（NOT_REGISTER、REGISTER_SUCCESS）
    checkIcpStatus2(appletTypeIcpStatusList) {
      // 检查是否所有元素的checked属性都为true
      if (appletTypeIcpStatusList.every(item => item.checked)) {
        return null;
      }
      // 找到第一个checked为true的元素并返回其icpStatus值
      const found = appletTypeIcpStatusList.find(item => item.checked);
      return found ? found.icpStatus : null;
    },

  }
}


</script>


<style scoped lang="less">
    .classesList{
      display: flex;
      .classes{
        min-width: 120px;
        height: 30px;
        border-radius: 10px;
        background-color: #4bc2ff;
        margin-left: 10px;
        flex-wrap: wrap;
        line-height: 30px;
        text-align: center;
      }
    }
    .keywordList{
      margin-top: 20px;
      margin-left: 10px;
      .checkAll{
        margin-bottom: 10px;
      }
      .spantext_one{
        margin-top: 30px;
        .spantext{
          font-size: 20px;
          margin-right: 50px;
        }
      }
      .but{
        margin-right: 10px;
      }

    }
</style>