<script>
export default {
    name: "ICPStatus",
    props: {
        /**
         * 表中这一行的数据
         */
        row: { type: Object },
        /**
         * 刷新列表的控制
         */
        fresh_table_bit:{
            type:Boolean
        },
    },
    data() {
        return {
            /**
             * icp备案状态
             */
            icp_status:{
                name_map_value:{
                    "NOT_REGISTER":"未备案",
                    "PLATFORM_REJECT":"平台审核驳回",
                    "PLATFORM_AUDITING":"平台审核中",
                    "MIIT_AUDITING":"监管审核中",
                    "MIIT_REJECT":"监管审核驳回",
                    "REGISTER_SUCCESS":"已备案",
                }
            },
        };
    },
    computed: {
        /**
         * icp备案状态
         */
        icp_status_text(){
            return this.icp_status.name_map_value[this.row.icpStatusAlipay] || "未知状态";
        },
        /**
         * 是否显示icp备案状态的详细信息
         * @returns {boolean}   是否显示icp备案状态的详细信息
         */
        is_show_icp_status_detail(){
            return this.icpStatusDetail.time && this.icpStatusDetail;
        },
        //
        icpStatusDetail(){
            if(this.row.icpStatusDetailJson){
                return JSON.parse(this.row.icpStatusDetailJson);
            }
            return {};
        },
        /**
         * icp备案状态的详细信息
         */
        icp_status_detail() {
            if(this.is_show_icp_status_detail){
                const time = "更新时间：" + this.icpStatusDetail.time;
                const detail = "备案信息：" + (this.icpStatusDetail.reason?this.icpStatusDetail.reason:"暂无信息");
                return time + '\n' + detail;
            }
            return "暂无信息";
        }
    },
    methods: {
        /**
         * 点击icp备案状态的详细信息
         */
        async    click_icp_status_detail(){
            const { data: res } = await this.$http.post(
                'alipay/icp/freshIcpStatus',
                {
                    appId: this.row.appId
                }
            );
            if(res.code === 200){
                this.$message.success("刷新成功");
                this.$emit("update:fresh_table_bit",false); //刷新列表
            }else{
                this.$message.error("刷新失败");
            }
        }
    }
}
</script>

<template>
<div>
    <el-popover
        class="item"
        placement="top-start"
        title="ICP备案详情"
        width="210"
        trigger="hover"
        :content="icp_status_detail">
        <div slot="reference"   class="status" @click="click_icp_status_detail">{{icp_status_text}}</div>
    </el-popover>
<!--    <el-tooltip class="item" effect="dark" placement="right"   :content="icp_status_detail">-->
<!--        -->
<!--    </el-tooltip>-->
</div>
</template>

<style scoped lang="less">
.item{
    .status{

    }
}
</style>