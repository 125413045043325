<template>
    <div		class="container">
		<div	class="mask"  v-if="is_show_mask">
			<div 	class="loading_text"	>正在加载中</div>
			<img  class="loading_icon"	src="./image/Loading.svg"	/>
		</div>
		<el-button type="primary" @click="add">添加</el-button>
		<el-button type="primary" @click="clear">清空</el-button>
		<el-table	:data="list">
				<el-table-column  label="类别">
					<template slot-scope="scope">
                        <!--
                            原先的选择器，
                            其中 `list.push({});list.pop();` 为了触发 vue 视图更新，
                            由于 vue2 直接通过数组索引修改元素，vue 监测不到状态变化，不会触发 vue 视图
                        -->
						<!--<el-select :value="scope.row.type" placeholder="请选择"	@change="type=>{-->
						<!--	list[scope.$index]  = navigation_type_options.find(e=>e.type==type);list.push({});list.pop();-->
						<!--}">-->
						<!--	<el-option	v-for="(item,index) in navigation_type_options"	-->
						<!--		:key="index"	:label="item.label"	:value="item.type"></el-option>-->
						<!--</el-select>-->
                        <!--
                            将原先的选择器修改为级联选择器，
                            @change 中的箭头函数用于接收 element 默认向节点选择改变处理事件传递的参数，
                            navigationTypeChangeHandler 用于处理数据列表中数据项 type 的修改.
                            el-cascader 组件中当前选中节点值的绑定不要使用 v-model，使用 value 向其中传值，值的修改由
                            navigationTypeChangeHandler 方法实现，避免二者发生冲突（如果未来依旧发生冲突，考虑 el-select 嵌套实现）
                        -->
                        <el-cascader
                            :value="scope.row.type"
                            :options="navigation_type_options"
                            :props="{ expandTrigger: 'hover' }"
                            @change="(selected) => navigationTypeChangeHandler(selected, scope)"
                        ></el-cascader>
					</template>
				</el-table-column>
				<el-table-column  label="顶部左上角标题"  width="150">
						<template slot-scope="scope">
								<el-input v-model="scope.row.title" placeholder="标题为空为本来的默认值"	clearable></el-input>
						</template>
				</el-table-column>
				<el-table-column  label="底部名字"  width="150">
						<template slot-scope="scope">
								<el-input v-model="scope.row.name" placeholder="不能为空"	clearable></el-input>
						</template>
				</el-table-column>
				<el-table-column  label="排序"	width="150">
					<template slot-scope="scope"	>
						<el-button type="primary" @click="row_up(scope.$index)"	:disabled="scope.$index==0"	size="mini">向上</el-button>
						<el-button type="primary" @click="row_down(scope.$index)" :disabled="scope.$index==list.length-1"	size="mini">向下</el-button>
					</template>
				</el-table-column>
				<el-table-column  label="操作"  width="80">
					<template slot-scope="scope"	>
						<el-button type="primary" @click="delete_row(scope.$index)" size="mini">删除</el-button>
					</template>
				</el-table-column>
		</el-table>
    </div>
</template>

<script>
	import axios from 'axios'
    import navigationTypeOptions
        from "@/components/thirdParty/smallRoutine/applet1/childrenPage/smallroutineConfiguration/constant/navigation-type-options";

export default{
    components:{

    },
    props: {
		input_list:{	type:String },
		list_max_length:{	type:Number, default:5}
    },
    data() {
        return {
			// 这个是数据列表，在created那边有初始化，对input_list就是输入进来的String格式的列表进行json的转化，
			// 转化为Array类型的列表
            list:[],
            is_first_watch:true,
            is_show_mask:true,
            // 配置小程序底部导航栏项类型的选项列表
            // 数据抽取到 @/components/thirdParty/smallRoutine/applet1/childrenPage/smallroutineConfiguration/constant/navigation-type-options
            // 文件中
            navigation_type_options: navigationTypeOptions,
        }
    },
    methods:{
//对数据进行增删改查的操作
		//添加一行数据
        add(){
			if(this.list_max_length == this.list.length)	{	//如果长度超过了，就不要再添加了
				this.$message.error("超过长度了,无法再添加");
			}else{
				this.list.push(this.navigation_type_options[0]);	//默认添加第一个
			}
        },
		//排序的名次上升，指排序的下标上升
        row_up(index){
        	let temp = this.list[index] ;
        	this.$set(this.list,index,this.list[index-1]);
        	this.$set(this.list,index-1,temp);
        },
		//排序的名次下降，指排序的下标下降
        row_down(index){
        	let temp = this.list[index] ;
        	this.$set(this.list,index,this.list[index+1]);
        	this.$set(this.list,index+1,temp);
        },
		//删除一行的数据
        delete_row(index){
        	this.list.splice(index,1);
        },
		//清空所有的数据
        clear(){
        	this.list =[];
        },

//下面处理初始化数据的，因为props进来的数据比较慢，需要等props内的参数进来才开始初始化的操作
		//根据输入进来的数据 处理
		async	initiailize_data(){
			//先进行解码（在网络传输中会变内容，所以传输的时候会进行编码解码）
			//后对于JSON格式经行 转化
			this.list = JSON.parse(decodeURI(this.input_list)) ;
		},
		//在本地数据还没有初始化之前，把页面内容遮盖，然后轮询props是否初始化完成，再进行本地数据初始化
		wait_util_not_undefined(callbackFunction){
			const  intervalTimer = setInterval(
				()=>{
					if(this.input_list != undefined){
						this.initiailize_data();
						clearInterval(intervalTimer);
						this.is_show_mask = false;
					}
				},
				100
			)
		},
        /**
         * 底部导航栏类型改变处理函数
         *
         * @param selected 级联选择器选中节点的 values 值
         * @param scope 表格当前单元格所在行列及数据项索引组成的对象
         */
        navigationTypeChangeHandler(selected, scope) {
            // 从选项列表数据中选出当前选中的节点
            // 当前查找的层级
            let level = 0
            // 当前查询层级的列表
            let list = this.navigation_type_options
            const find = () => {
                // 查找符合当前层级 type 的项
                list = list.find(item => item.value === selected[level])
                // 查到最后一个层级
                // 因为在此层级的列表就是页面中当前选中的节点所在的列表
                if (level >= selected.length - 1) {
                    // this.list 为表格中渲染的数据
                    // 更新当前在页面中修改的表格项(行)
                    this.list.splice(scope.$index, 1, list)
                    // 推出递归
                    return
                }
                // 递归
                level++
                list = list.children
                find()
            }
            // 开始查询
            find()
        }
    },
    computed:{

	},
    created(){
		this.wait_util_not_undefined();
    },
	watch:{
		//对结果进行解码，上传给父组件
		list:{
			deep:true,
			handler(newValue){
				this.$emit("update:input_list",encodeURI(JSON.stringify(newValue)));
			}
		}
	}
}
</script>

<style  lang="less" scoped>
	.container{
		position: relative;
	}
	.mask{
		position: absolute;
		z-index: 200;
		background-color: white;
		opacity: 0.8;
		width: 100%;
		height: 100%;

		display: flex;	justify-content: center;	align-items: center;

		.loading_text{
			font-size: 23px;
		}

		.loading_icon{
			margin-left: 6px;
			width: 30px;		height: 30px;
			animation-name: rotate;
			animation-duration: 2s;
			animation-timing-function: linear;
			animation-iteration-count: infinite;
		}
		@keyframes rotate {
			0%		{	transform: rotate(0deg);	}
			50%		{	transform: rotate(180deg);	}
			100%		{	transform: rotate(360deg);	}
		}
	}
</style>