<template>
<el-popover    :placement="popover_direction"   width="820"	style="z-index:-10;"    :trigger="is_editable?'hover':'manual'"
    @show="is_show_block_container = true"  @hide="is_show_block_container = false">
	<div	 slot="reference">
        <el-image
            fit="contain"
            :style="image_size_style"
            :src="image_url"
            referrer-policy="no-referrer"
            :lazy="true">

        </el-image>
	</div>
    <div    class="image_edit" v-if="is_show_block_container">
        <div	class="block_container">
            <div	class="block">
                <!-- 删除照片按钮 -->
                <el-button		size="mini"	style="margin-left: 0;"	type="primary"		v-if="current_state == 'edit'"	:disabled="current==-1"	@click="delete_image">删除该照片</el-button>
                <!-- 添加照片按钮 -->
                <el-upload		class="upload-demo"		name="image"	:action="upload_image_action_path"	:headers="upload_image_head"
                                  :on-success="success_upload"	:on-error="erorr_upload"		:show-file-list='false'
                                  :data="{path:prefix_path,isNeedCompress:is_need_compress}"
                                  :before-upload="handleBeforeUpload">
                    <el-button		size="mini"	style="margin-left: 0;"	type="primary"		v-if="current_state == 'edit'">添加照片</el-button>
                </el-upload>
                <!-- 选择照片按钮 -->
                <el-button		size="mini"	type="primary"		:disabled="current==-1"	v-if="current_state == 'choose'"	@click="submit">选择该图片</el-button>
                <!-- 状态选择 -->
                <el-button		size="mini"	style="margin-left: 0;"	type="primary"	@click="change_state">{{current_state == 'edit'?'返回选择':'编辑图片'}}</el-button>
            </div>
            <!-- 实际照片展示,一般展示多张图片 -->
            <div	class="block"	v-for="(item,index)	in prefix_path_Map_image_paths[prefix_path]"	:key="index">
                <!-- 如果用户选择了这张图片，那么就会显示一个打勾 -->
                <img	class="correct"		v-if="current==index" src="./image/correct.svg"/>
                <el-image	fit="contain" class="img"	:class="current==index?'img_choosed':'img_no_choosed'"	:src="item" 	@click="choose_image(index)"/>
            </div>
        </div>
        <div    class="direct_edit">
            <el-button	type="primary"  :disabled="!direct_choose_image_url"	@click="direct_submit(direct_choose_image_url)">直接选择该路径图片</el-button>
            <el-input   v-model="direct_choose_image_url"    style="width: 600px;margin-left: 20px;"    placeholder="请放置图片路径"></el-input>
        </div>
    </div>

</el-popover>
</template>

<script>
	import axios from 'axios';
	import store from '@/store/index.js'
	import { mapState } from 'vuex';
    import {handleResponse} from "@/services/wCommonFunction";
export default{
    components:{
        
    },
    props: {
        image_url:{	//图片的路径,支持.sync
          type:String,
          require:true,
        },
        prefix_path:{	//图片的前缀路径，就是他是哪一个类目下的图片，例如: redpacket/small_mall_1
          type:String,
          require:true,
        },
        image_size:{	//图片的大小
          type:Number,
          default:100,
        },
        popover_direction:{
            type:String,
            default:'right',
        },
        is_editable:{
          type:Boolean,
          default:true,
        },
        // 用于控制当前图片选择器允许的图片格式，例如 :available_image_types="['jpeg', 'png','jpg']"
        available_image_types:{
            type: Array,
            default: () => []
        },
        // 是否需要压缩,是的话压缩成webp格式
        is_need_compress: {
            type: Boolean,
            default: true
        }
    },
    data() {
        return {
            current:-1,	//选择的图片的下标
            current_state:'choose',//如果是choose，那么就是选择图片，如果是编辑，那就是edit
            upload_image_head:{//token配置
              Authorization:sessionStorage.getItem("token")
            },
            is_show_block_container:false,
            direct_choose_image_url:null,
        }
    },
    methods:{
		//初始化数据
		async	initialize_data(){
			//获取图片路径
			store.dispatch("image_choose/prefix_path_Map_image_paths_add",this.prefix_path);
		},
		async	fresh_data(){
			store.dispatch("image_choose/prefix_path_Map_image_paths_reset",this.prefix_path);
		},
		//选中图片
		choose_image(index){
			this.current = index;
		},
		//提交图片
		submit(){
            this.$emit('update:image_url',this.prefix_path_Map_image_paths[this.prefix_path][this.current] );
		},
        //直接选择图片
        direct_submit(submit_image_url){
            this.$emit('update:image_url',submit_image_url);
        },
		//改变状态，一种是eidt就是编辑图片，一种是choose就是选择照片
		change_state(){
			if(this.current_state == 'choose'){
				this.current_state = 'edit';
			}else{
				this.current_state = 'choose';
			}
		},
		//成功上传图片文件
		success_upload(res){
            if(res.code != 200){
                this.$message.error("失败上传文件"+res.message);
                return;
            }
			this.$message.success("成功上传文件");
			this.fresh_data();
		},
		//失败上传图片文件
		erorr_upload(res){
			this.$message.error("失败上传文件"+res.data.message);
		},
		//删除图片
		async	delete_image(){
			let	form_data = new FormData();
			form_data.append("webPath",this.prefix_path_Map_image_paths[this.prefix_path][this.current]);
			const result = (await axios({
				method:'DELETE',
				data:form_data,
				headers:{"Content-Type":'multipart/form-data;charset=UTF-8'},
				url:this.$httpUrl+"imageManager/del",
			}))
			if(result.status==200&&result.data.code == 200){
				this.$message.success("删除成功");
			}else{
				this.$message.error("删除失败");
			}
			this.fresh_data();
		},
        // 从文件名中获取文件扩展名
        getFileExtension(filename) {
            const parts = filename.split('.');
            return parts.length > 1 ? parts.pop() : '';
        },
    // 处理图片上传前触发的事件
        handleBeforeUpload(file) {
            const extensionName = this.getFileExtension(file.name);
            // 当没有向当前组件传递允许的图片格式，则允许所有图片格式上传
            if (this.available_image_types.length === 0){
                return true;
            }
            // 否则判断当前上传的图片的格式是否在允许的图片格式列表中
            let isAvailable = this.available_image_types.includes(extensionName);
            // 如果当前上传的图片的格式不允许
            if (!isAvailable) {
                this.$message.error('上传的图片只能是 ' + this.available_image_types.join('/') + ' 格式!');
            }
            return isAvailable;
        }
    },
    computed:{
		upload_image_action_path(){//上传图片的路径
			return this.$httpUrl + 'imageManager/store';
		},
		image_size_style(){	//图片大小设置
			return {width:this.image_size+'px',height:this.image_size + 'px'};
		},
		...mapState({
			prefix_path_Map_image_paths: state=>state.image_choose.prefix_path_Map_image_paths
		})
	},
    created(){
        this.initialize_data();
    },
}
</script>

<style  lang="less" scoped>
.el-popover__reference{
    padding-left: 10px;
    padding-right: 10px;
    max-width: 200px;
}
.image_edit{
    display: flex;
    flex-direction: column;
    .block_container{
        height: 150px;
        min-width: 700px;	max-width: 800px;	overflow-x: auto;
        display:flex;
        align-items: center;
        .block{
            height: 120px;	width: 120px;
            margin-right: 20px;
            display: flex;	flex-direction: column;		align-items: center;		justify-content: space-evenly;
            position: relative;
            .img{
                height: 80px;	width: 80px;
                cursor: pointer;
            }
            .img_choosed{	//被选中
                border: 5px solid #71d5a1;
                border-radius: 10px;
                // filter: brightness(120%);
            }
            .img_no_choosed{	//没被选中
                border: 2px dashed #ccc;
                border-radius: 10px;
                background-color: #eee;
                filter: brightness(70%);
            }
            .correct{
                width: 50px;		height: 50px;
                filter: invert(67%) sepia(8%) saturate(3667%) hue-rotate(56deg) brightness(99%) contrast(81%);
                opacity: 0.90;
                z-index: 1000;
                position: absolute;
            }
        }
        .direct_edit{
            height: 15px;
            width: 100%;
            display: flex;
            flex-wrap: nowrap;
            justify-content: space-between;
            .el-input{
                width: 600px;
            }
        }
    }
}


</style>