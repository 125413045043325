<template>
<div>
    <el-form label-width="140px">
        <el-form-item label="活动1保存图片文字">
            <el-input
                v-model="data_value.activity1_save_image_txt"
                placeholder="默认：红包码领取">
            </el-input>
        </el-form-item>
        <el-form-item label="活动1扫一扫">
            <el-input
                v-model="data_value.activity1_scan"
                placeholder="默认：扫描红包码">
            </el-input>
        </el-form-item>
        <el-form-item label="活动2保存图片文字">
            <el-input
                v-model="data_value.activity2_save_image_txt"
                placeholder="默认：保存收钱码">
            </el-input>
        </el-form-item>
        <el-form-item label="活动2扫一扫">
            <el-input
                v-model="data_value.activity2_scan"
                placeholder="默认：扫描收钱码">
            </el-input>
        </el-form-item>
    </el-form>
</div>
</template>

<script>
export default{
    components:{

    },
    props: {
        json_value:{
            type:String ,
            default:""
        }
    },
    data() {
        return {
            data_value:{
                "activity1_save_image_txt":"保存图片",
                "activity2_save_image_txt":"保存图片",
                "activity1_scan":"扫码",
                "activity2_scan":"扫码",
            },
            is_show_mask:true,
        }
    },
    methods:{
        wait_util_not_undefined(){
            const  intervalTimer = setInterval(
                ()=>{
                    if(this.data_value != undefined){
                        this.init();
                        clearInterval(intervalTimer);
                    }
                },
                1000
            )
        },
        init(){
            if(this.json_value) {
                this.data_value = JSON.parse(this.json_value);
            }
        }
    },
    computed:{

    },
    created(){
        this.wait_util_not_undefined();
    },
    watch:{
        //对结果进行解码，上传给父组件
        data_value:{
            deep:true,
            handler(newValue){
                this.$emit("update:json_value",JSON.stringify(newValue));
            }
        }
    }
}
</script>

<style  lang="less" scoped>
</style>