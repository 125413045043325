<template>
	<div v-if="zzz">
		
		<div  v-for="(item,index) in updateFormdata" :key="index" >
			<el-form
			:model="item"
			ref="updateFormRed"
			label-width="auto"
			element-loading-text="拼命加载中"
			element-loading-spinner="el-icon-loading"
			element-loading-background="rgba(0, 0, 0, 0.8)"
			>
			<el-divider content-position="left">贷款{{index}}</el-divider>
			<el-form-item label="贷款" prop="index" width="180" class="top_loans">
			</el-form-item>
			<el-button  class="top_form" type="primary" @click="pop_loans(index)">编辑</el-button>
			</el-form>	
		</div>
			<!-- //弹窗 -->
		<el-dialog :visible.sync="compile"
			   width="1000px">
			<el-form
			:model="updateForm"
			ref="updateFormRed"
			label-width="auto"
			
			element-loading-text="拼命加载中"
			element-loading-spinner="el-icon-loading"
			element-loading-background="rgba(0, 0, 0, 0.8)"
			>
				<div class="form">
					<el-form-item label="贷款链接地址" prop="h5Address">
						<el-input v-model="updateForm.h5Address" placeholder="请输入跳转h5" clearable></el-input>
					</el-form-item>
				  <el-form-item label="是否展示顶部图片" prop="isShowBigImage">
					<el-switch v-model="updateForm.isShowBigImage"></el-switch>
				  </el-form-item>
				  <el-form-item label="是否跳转" prop="isShowH5">
					<el-switch v-model="updateForm.isShowH5"></el-switch>
				  </el-form-item>
				  <el-form-item label="图片跳转的h5路径" prop="h5Path">
					<el-input v-model="updateForm.h5Path" placeholder="请输入跳转h5" clearable></el-input>
				  </el-form-item>
				  <el-form-item label="顶部图片设置">
					<image_choose :image_url.sync="updateForm.bigImage" prefix_path="redpacket/lucard/topSwiper">
					</image_choose>
				  </el-form-item>
				  <div v-if="popup" class="zzz">
				    <!-- 弹出层的内容 -->
				    {{popup_title}}
				  </div>
				  <el-form-item label="是否展示卡片列表上方的标题" prop="isShowTitle">
					<el-switch v-model="updateForm.isShowTitle"></el-switch>
				  </el-form-item>
				  <el-form-item label="卡片列表上方的标题的内容" prop="title">
					<el-input v-model="updateForm.title" placeholder="请输入标题的内容" clearable></el-input>
				  </el-form-item>
				  <el-form-item label="是否展示卡片列表" prop="isShowCardList">
					<el-switch v-model="updateForm.isShowCardList"></el-switch>
				  </el-form-item>
				  <el-form-item label="信用卡配置" prop="cardList:this.up">
					<card_table v-model="updateForm.cardList"></card_table>
				  </el-form-item>
				   <el-form-item label="卡片列表中按钮的名称" prop="buyTitle">
					<el-input v-model="updateForm.buyTitle" placeholder="请输入按钮的名称" clearable></el-input>
				  </el-form-item>
				  <el-button  class="button" type="primary" @click="seve">保存</el-button> 

				</div>
			</el-form>  
		</el-dialog>
		
	</div>
</template>

<script>
	import axios from 'axios';
	import store from "@/store";
	import {
	  getConfiguration,
	  updateConfiguration,
	} from "@/services/thirdParty/smallRoutine/applet1/configuration.js";
 import card_table from '@/components/thirdParty/other/lucard/component/card_table.vue';
 import image_choose from '@/components/thirdParty/smallRoutine/applet1/childrenPage/smallroutineConfiguration/image_choose.vue'	//图片选择器
	export default {
		components:{
			card_table,
			image_choose,
		},
	    data() {
	      return {
			updateFormdata:[],
			updateForm:{},
			compile:false,
			zzz:false,
			popup:false,
			popup_title:'保存成功',
	      };
	    },
	    watch: {
	    },
	   async created() {
			for(let i=1;i<=10;i++){
				const result = await axios({
						method:"GET",
					  url: this.$httpUrl + 'loans/getById?'+'id='+i,
				})
				this.updateFormdata.push(result.data.message)
			}
			this.zzz=true
	    },
	    methods: {
			async seve(){
				const result = await axios({
					url: this.$httpUrl + 'loans/update',
					method:"PUT",
					data: this.updateForm,
				})
				this.popup=true
				this.popup_title='保存成功'
				setTimeout(()=>{ this.popup=false},2000)
			},
			pop_loans(index){
				this.indexdata = index;
				this.updateForm = this.updateFormdata[this.indexdata]
				this.compile= true;
			}
	    },
	};
</script>
	  
<style lang="less" scoped>
	.top_loans{
		
	}
	.zzz {
		position: fixed;
		top: 30%;
		left: 30%;
		margin-left: 280px;
		color: red;
	  background-color: #f8f8ff;
	  padding: 20px;
	  box-shadow: 0 4px 8px rgba(0,0,0,0.1);
	  z-index: 100;
	}
	.top_form{
		margin-top: 40rpx;
		margin-left: 280px;
		display: flex;
		
	}
	.button{
	    margin-top: 20px;
	}
</style>
