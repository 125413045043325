<template>
  <div>
    <div class="text">服务类目 ------>></div>
    <el-cascader
        v-model="selectedValues"
        :options="categoryList"
        :props="{ expandTrigger: 'hover' }"
        @change="categoryListChangeHandler"
    ></el-cascader>

    <div class="text">客服词条 ------>></div>
    <defaultConfig @sendServiceData="handleServiceData"></defaultConfig>
    <div style="margin-bottom: 20px;">
      <span class="spantext1">小程序的总任务的数量：{{appletAmount}}</span>
      <span class="spantext2" style="color: #af7a34">小程序已执行的数量：{{count}}</span>
    </div>
    <div>
      <span class="spantext1">客服的总任务的数量：{{service_appletAmount}}</span>
      <span class="spantext2" style="color: #af7a34">客服的已执行的数量：{{messages.length}}</span>
      <span class="spantext2">客服的成功的数量：{{service_count}}</span>
      <span class="spantext3">客服的失败的数量：{{messages.length - service_count}}</span>
    </div>

    执行间隔时间至少：
    <el-input  type="number" style="width: 100px"  placeholder="默认10"   min="0"   v-model="sleepLeastTime"></el-input>
    秒 至多
    <el-input  type="number" style="width: 100px"  placeholder="默认60"   min="0"   max="1000"   v-model="sleepMostTime"></el-input>

      <div style="margin-top: 20px">
      <el-button class="but" type="primary" plain :loading="useDeployLoading" @click="multiServiceApply(1)" >{{ useDeployLoading? '加载中' : '执行选择的类别' }}</el-button>
      <el-button class="but" type="warning" plain :loading="useDeployLoading" @click="multiServiceApply(2)">{{ useDeployLoading? '加载中' : '执行选择的第三方' }}</el-button>
      <el-button class="but" type="danger" plain @click="ceaseTask()">强制终止执行</el-button>
      <el-button class="but" type="info" plain @click="dialogVisible = true">查看执行的全部结果</el-button>
      <el-dialog
          title="查看执行的全部结果"
          :visible.sync="dialogVisible"
          width="80%"
          append-to-body>
        <exhibitionMessagesTheSecond :messages="messages"></exhibitionMessagesTheSecond>
      </el-dialog>
      <el-button style="background-color: #6c6c6c; color: #ffffff" plain @click="getTask">刷新↕获取上一次执行的结果</el-button>
    </div>

  </div>
</template>

<script>
import defaultConfig from "../../../other/redpacket/menuPage/defaultConfig.vue";
import categoryTypeOption from "../../zkyTemplate/constant/category-type-option";
import exhibitionMessagesTheSecond from "../gatherInformation/exhibitionMessagesTheSecond.vue";

let intervalId = null

export default {
  components: {
    exhibitionMessagesTheSecond,
    defaultConfig,
  },
  props: [
      /**
       * 1. 选中的类别
       * 2. 选中的第三方
       * 3. 第三方类型的 区分未备案/已备案
       * 4. 上一次历史记录的redisKey
       */
      "checkedCities",
      "dimensionArr",
      "appletTypeIcpStatusList",
      "multiServiceApplyRedisKey",
      "multiServiceApplyLock"
  ],
  data() {
    return {
      //弹出对话框
      dialogVisible: false,
      //加载中
      useDeployLoading: false,
      //小程序
      appletAmount: 0, //总数量
      count: 0, //已执行数
      //全部信息列表
      messages: [],
      redisKey: null,
      //客服
      service_appletAmount: 0, //总数量
      service_count: 0, //成功数

      // 默认选中的值
      selectedValues: [],
      // 小程序类目列表
      categoryList: categoryTypeOption,
      // 执行时间间隔
      sleepLeastTime:null, // 最短间隔
      sleepMostTime:null, // 最长间隔
      serviceApplyVo: {
        // 行业类目ID
        categoryId: "",
        // 行业类目名称
        categoryName: "",
        // 服务提报选中的行id
        selectRowIds: [],
        // 服务提报需要的链接生成的数据
        urlData: {},
      },

    }
  },
  methods: {
    //服务类目和客服词条数据：
    categoryListChangeHandler(selected) {
      let list = this.categoryList;
      let selectedNode = null;
      // 遍历selected数组，逐级查找
      for (let level = 0; level < selected.length; level++) {
        selectedNode = list.find(item => item.value === selected[level]);
        if (!selectedNode) {
          return;
        }
        // 继续查找下一级的children，除非已经是最后一个层级
        if (level < selected.length - 1) {
          list = selectedNode.children; // 更新list为下一层级的children
        }
      }
      // 当遍历完selected后selectedNode就是用户最终选中的节点
      if (selectedNode) {
        // 在这里处理你选中的节点，赋值给post的categoryID
        this.serviceApplyVo.categoryId = selectedNode.value;
        this.serviceApplyVo.categoryName = selectedNode.label;
      }
    },
    handleServiceData(serviceData){
      this.serviceApplyVo.selectRowIds = serviceData.selectedRows;
      this.serviceApplyVo.urlData = serviceData.urlData;
    },

    //获取 第三方类型的 区分未备案/已备案 =》 返回的是 icpStatus 值（NOT_REGISTER、REGISTER_SUCCESS）
    checkIcpStatus2(appletTypeIcpStatusList) {
      // 检查是否所有元素的checked属性都为true
      if (appletTypeIcpStatusList.every(item => item.checked)) {
        return null;
      }
      // 找到第一个checked为true的元素并返回其icpStatus值
      const found = appletTypeIcpStatusList.find(item => item.checked);
      return found ? found.icpStatus : null;
    },

    //查询历史记录
    async getTask() {
      const { data: res } = await this.$http.get(
          "/redisTasksBufferController/getTask?taskId=" + this.multiServiceApplyRedisKey
      );
      console.log("res", res)
      if (res.message != null) {
        //小程序总任务数
        this.appletAmount = res.message.taskSum? res.message.taskSum : '';
        //客服词条总任务数
        this.service_appletAmount = res.message.serviceAmount? res.message.serviceAmount : '';
        //结果列表
        this.messages = res.message?.multiAuditApplySbuMsgVoList? res.message?.multiAuditApplySbuMsgVoList : [];
        //客服成功的数量（从全部信息列表中判断operateMessage属性值为"成功"的数量）
        this.service_count = this.messages.filter(function(message) {
          return message.operateMessage === "成功";
        }).length;
        //小程序已执行的数量（从结果列表中对 appId进行去重，得到列表最后的长度）
        const uniqueApplds = new Set(this.messages.map(msg => msg.appId));
        this.count = uniqueApplds.size;
        // console.log("uniqueApplds：", uniqueApplds)
      }
    },

    //执行服务提报
    async multiServiceApply(is) {
      if(this.serviceApplyVo.categoryName == null || this.serviceApplyVo.categoryName == '') {
        this.$message.error("请先选择，服务类目");
        return;
      }
      if(this.serviceApplyVo.selectRowIds === [] || this.serviceApplyVo.selectRowIds.length < 1) {
        this.$message.error("请先选择，词条");
        return;
      }
      // 检查执行时间间隔
      if(!this.checkSleepTime()){
          this.useDeployLoadingB = false; //解除按钮等待状态
          return ;
      }

      this.useDeployLoading = true
      //清空上一次的记录
      this.appletAmount = 0;
      this.count = 0;
      this.service_appletAmount = 0;
      this.messages = [];
      this.service_count = 0;

      let batchClassIdList = this.checkedCities.map(city => city.id); //选中的类别
      let appletTypeListList = this.dimensionArr.map(city => city.id); //选中的第三方
      if (is === 1 && batchClassIdList.length < 1) {
        this.$message.error("请选择类别");
        this.useDeployLoading = false
        return
      }
      if (is === 2 && appletTypeListList.length < 1) {
        this.$message.error("请选择第三方");
        this.useDeployLoading = false
        return
      }
      let appletTypeIcpStatus = this.checkIcpStatus2(this.appletTypeIcpStatusList)

      let multiAppletTypeOrBatchClassIdVo = {
        batchClassIdList: is === 1? batchClassIdList : null,
        appletTypeList: is === 2? appletTypeListList : null,
        appletTypeIcpStatus: appletTypeIcpStatus,
        serviceApplyVo: this.serviceApplyVo,
        sleepLeastTime: this.sleepLeastTime, // 最短间隔
        sleepMostTime: this.sleepMostTime, // 最长间隔
      }

      //执行服务提报
      const { data: res } = await this.$http.post(
          "/thirdParty2/multiServiceApply",
          multiAppletTypeOrBatchClassIdVo
      );
      if (200 === res.code) {
        this.$message.warning("「批量服务提报」已提交，等待审核结果");
      } else {
        this.$message.error("「批量服务提报」提交失败:" + res.message);
        this.useDeployLoading = false
      }

      this.redisKey = res.message.redisKey;
      this.appletAmount = res.message.taskSum;
      //计算总共有多少客服（所有小程序 * 每个小程序需要执行的客服数）
      this.service_appletAmount = this.appletAmount * this.serviceApplyVo.selectRowIds.length;

      //查询进度
      this.startTaskProgress();
    },

    //轮询查 异步任务的进度
    async startTaskProgress() {
      intervalId = setInterval(async () => {
        //查询 小程序批量服务提报的进度
        const { data: res } = await this.$http.get(
            "/redisTasksBufferController/getTask?taskId=" + this.redisKey
        );
        console.log("服务提报进度：", res)

        if(500 === res.code) {
          this.$message.error("「批量服务提报」已暂停执行，等待第二天早上9点恢复");
          //解除按钮等待状态
          this.useDeployLoading = false;
          //清除定时器
          clearInterval(intervalId);
          //触发父组件
          this.$emit('updateGetList');
        }

        if (res.message != null) {
          //小程序总任务数
          this.appletAmount = res.message.taskSum? res.message.taskSum : '';
          //客服词条总任务数
          this.service_appletAmount = res.message.serviceAmount? res.message.serviceAmount : '';
          //结果列表
          this.messages = res.message?.multiAuditApplySbuMsgVoList? res.message?.multiAuditApplySbuMsgVoList : [];
          //客服成功的数量
          this.service_count = this.messages.filter(function(message) {
            return message.operateMessage === "成功";
          }).length;
          //小程序已执行的数量（从结果列表中对 appId进行去重，得到列表最后的长度）
          const uniqueApplds = new Set(this.messages.map(msg => msg.appId));
          this.count = uniqueApplds.size;
        }

        //所有任务进度完成（客服词条总数 = 结果列表总数）
        if (this.service_appletAmount === this.messages.length) {
          //解除按钮等待状态
          this.useDeployLoading = false;
          //清除定时器
          clearInterval(intervalId);
          //触发父组件
          this.$emit('updateGetList');
          this.$message.success("「批量服务提报」执行完成");
        }
      }, 5000); // 每5秒查询一次
    },

    //强制终止执行
    async ceaseTask() {
      const { data: res } = await this.$http.get(
          "/redisTasksBufferController/ceaseTask?lock=" + this.multiServiceApplyLock
      );
      console.log("终止res", res);

      //解除按钮等待状态
      this.useDeployLoading = false;
      //清除定时器
      clearInterval(intervalId);
      //触发父组件
      this.$emit('updateGetList');
      this.$message.success("强制终止执行成功");
    },
    /**
    * 检查执行时间间隔，也就是检查 sleepLeastTime 和 sleepMostTime 是否合法 <br>
    * @returns {boolean} 返回 true 表示合法，返回 false 表示不合法
    */
    checkSleepTime() {
      let isSleepTimeLessThanZero = (this.sleepLeastTime && this.sleepLeastTime < 0)
          || (this.sleepMostTime && this.sleepMostTime < 0);
      if(isSleepTimeLessThanZero){
          this.$message.error('最短间隔不能小于0');
          this.sleepLeastTime = null;
          return false;
      }
      if (this.sleepLeastTime && this.sleepMostTime &&this.sleepLeastTime > this.sleepMostTime) {
          this.$message.error('最短间隔不能大于最长间隔');
          this.sleepMostTime = null;
          this.sleepLeastTime = null;
          return false;
      }
      return true;
    }
  },
  created() {

  }
}
</script>

<style scoped lang="less">
.text{
  margin-top: 30px;
  margin-bottom: 10px;
}
.spantext1{
  color: #34a4f4;
  font-size: 20px;
  margin-right: 50px;
}
.spantext2{
  color: #5daf34;
  font-size: 20px;
  margin-right: 50px;
}
.spantext3{
  color: #ff0000;
  font-size: 20px;
  margin-right: 50px;
}
.but{
  margin-right: 10px;
}
</style>