<template>
  <div>
    <el-table :data="pagedServiceList">
      <el-table-column
        v-for="(item,index) in columns"
        :width="item.width==undefined?160:item.width"
        :key="index"
        :label="item.label"
        :prop="item.prop"
        :row-class-name="tableRowClassName"
      >
      </el-table-column>
      <!-- 审核时间列 -->
      <el-table-column width="160" label="审核时间">
        <template slot-scope="scope">
          {{ formatDate(scope.row.gmtCreate) }}
        </template>
      </el-table-column>

      <!-- 修改时间列 -->
      <el-table-column width="160" label="修改时间">
        <template slot-scope="scope">
          {{ formatDate(scope.row.gmtModified) }}
        </template>
      </el-table-column>
      <el-table-column width="100" label="当前状态">
        <template slot-scope="scope">
          {{ scope.row.auditStatus ? status_map[scope.row.auditStatus].label : '审核中' }}
        </template>
      </el-table-column>
      <el-table-column width="130" label="操作">
        <template slot-scope="scope">
          <el-button
            type="danger"
            icon="el-icon-delete"
            size="mini"
            style="margin-left: 10px;"
            @click="show_delete_confirm(scope.row)"
          ></el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      :current-page="currentPage"
      :page-sizes="[10, 20, 50, 100]"
      :page-size="pageSize"
      layout="total, sizes, prev, pager, next, jumper"
      :total="total"
    >
    </el-pagination>

    <!-- 刷新并更新数据库 -->
    <el-button
      type="primary"
      @click="initialize_data"
      style="margin-top: 20px;"
      :loading="useDeployLoading"
    >
      更新服务提报数据
    </el-button>
    <el-button style="margin-right: 10px;" type="danger" plain @click="ceaseTask()">强制终止执行</el-button>
    <el-button class="but" type="info" plain @click="dialogVisible = true">查看执行的全部结果</el-button>
    <el-dialog
      title="查看执行的全部结果"
      :visible.sync="dialogVisible"
      width="80%"
      append-to-body>
      <exhibitionMessagesTheSecond :messages="messages"></exhibitionMessagesTheSecond>
    </el-dialog>

    <div style="margin-top: 20px;">
      <span class="spantext1">更新服务提报数据的总进度：{{ serviceAmount }} 条</span>
      <span class="spantext2">更新服务提报数据已执行的进度：{{ completedCount }} 条</span>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import {mapState} from 'vuex'
import exhibitionMessagesTheSecond
  from "@/components/thirdParty/smallRoutine/list/gatherInformation/exhibitionMessagesTheSecond.vue";

let intervalId = null

export default {
  components: {exhibitionMessagesTheSecond},
  props: {
    appId: {
      type: String,
    },
    serviceList: {
      type: Array,
    }
  },
  data() {
    return {
      columns: [
        {label: '审核完成的服务', prop: 'serviceName',},
        {label: '审核原因', prop: 'auditText', width: 500},
      ],
      status_map: {
        'AGREE': {label: '审核通过'},
        'REJECT': {label: '审核驳回'},
      },
      currentPage: 1,
      pageSize: 10,
      total: 0,
      redisKey: null,
      serviceAmount: 0, // 更新数据时总进度
      completedCount: 0, // 更新数据的完成计数
      useDeployLoading: false, //加载中
      serviceListQueryLock: null,
      //全部信息列表
      messages: [],
      //弹出对话框
      dialogVisible: false,
    }
  }, methods: {
    async initialize_data() {
      if (this.total === 0) {
        this.$message.error("请先进行服务提报再尝试更新数据");
        return
      }

      this.useDeployLoading = true;
      // 清空记录
      this.serviceAmount = 0;
      this.completedCount = 0;
      this.messages = [];

      let url = this.global_url + "thirdParty2/serviceListQuery"
      let send_data = {
        appId: this.appId,
        categoryId: 'C101330469',
      }
      const res = await axios({
        method: "POST",
        url: url,
        data: send_data,
      })
      if (res.data.code === 200) {
        this.$message.success("「更新服务提报数据」已提交，等待更新结果");
      } else {
        // 响应失败时的处理
        this.$message.error(`更新失败: ${res.data.message}`);
        this.useDeployLoading = false
      }
      this.serviceListQueryLock = res.data.message.lock;
      this.redisKey = res.data.message.redisKey;
      this.serviceAmount = res.data.message.taskSum;
      //查询进度
      await this.startTaskProgress();
    },

    //轮询查 异步任务的进度
    async startTaskProgress() {
      intervalId = setInterval(async () => {
        //查询 小程序批量服务提报的进度
        const {data: res} = await this.$http.get(
          "/redisTasksBufferController/getTask?taskId=" + this.redisKey
        );
        console.log("更新服务提报数据进度：", res)

        if (res.message != null) {
          //总任务数
          this.serviceAmount = res.message.taskSum ? res.message.taskSum : '';
          //总完成数
          this.completedCount = res.message.auditedAccomplishCount ? res.message.auditedAccomplishCount : '';
          //结果列表
          this.messages = res.message?.multiAuditApplySbuMsgVoList? res.message?.multiAuditApplySbuMsgVoList : [];
        }

        //所有任务进度完成（客服词条总数 = 已完成的任务数）
        if (this.serviceAmount === this.completedCount) {
          //解除按钮等待状态
          this.useDeployLoading = false;
          //清除定时器
          clearInterval(intervalId);
          //触发父组件
          this.$emit("refresh_service", this.appId);
          this.$message.success("「更新服务提报数据」执行完成");
        }
      }, 5000); // 每5秒查询一次
    },

    //强制终止执行
    async ceaseTask() {
      const {data: res} = await this.$http.get(
        "/redisTasksBufferController/ceaseTask?lock=" + this.serviceListQueryLock
      );
      console.log("终止res", res);

      //解除按钮等待状态
      this.useDeployLoading = false;
      //清除定时器
      clearInterval(intervalId);
      //触发父组件
      this.$emit("refresh_service", this.appId);
      this.$message.success("强制终止执行成功");
    },

    // 页码变化处理
    handleCurrentChange(newPage) {
      this.currentPage = newPage;
    },
    // 每页条数变化处理
    handleSizeChange(newSize) {
      this.pageSize = newSize;
      this.currentPage = 1; // 改变每页条数后，重置到第一页
    },
    // 时间格式化方法
    formatDate(dateString) {
      if (!dateString) return '';  // 处理空值
      const date = new Date(dateString);
      const year = date.getFullYear();
      const month = (date.getMonth() + 1).toString().padStart(2, '0');
      const day = date.getDate().toString().padStart(2, '0');
      const hours = date.getHours().toString().padStart(2, '0');
      const minutes = date.getMinutes().toString().padStart(2, '0');
      const seconds = date.getSeconds().toString().padStart(2, '0');
      return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
    },
    tableRowClassName({row, rowIndex}) {
      if (row.status === 'REJECT') {
        return 'warning-row';
      } else if (row.status === 'AGREE') {
        return 'success-row';
      }
      return 'warning-row';
    },
    async show_delete_confirm(row) {
      let alertString = '此操作将删除该服务' + row.serviceName + ', 是否继续?'
      this.$confirm(alertString, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(async () => {
          await this.delete_service(row);
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: '已取消删除'
          });
        });
    },
    //删除服务
    async delete_service(row) {
      let url = this.global_url + "thirdParty2/serviceDelete"
      let send_data = {
        appId: row.appId,
        serviceCode: row.serviceCode
      }
      const res = await axios({
        method: "DELETE",
        url: url,
        data: send_data,
      })
      if (res.data.message === "成功" && res.data.code === 200) {
        this.$message.success("删除成功");
        // 直接从列表中移除删除的服务
        this.serviceList = this.serviceList.filter(item => item.serviceCode !== row.serviceCode);
      } else {
        // 响应失败时的处理
        this.$message.error(`删除失败: ${res.data.message}`);
      }
    },
  }, computed: {
    ...mapState({
      global_url: state => state.global_base_url.main
    }),
    // 计算分页后的列表
    pagedServiceList() {
      const start = (this.currentPage - 1) * this.pageSize;
      const end = this.currentPage * this.pageSize;
      return this.serviceList.slice(start, end);
    }
  },
  watch: {
    serviceList: {
      immediate: true,
      handler(newVal) {
        this.total = newVal.length; // 设置总条数
      }
    }
  }
}
</script>

<style scoped lang="less">
.spantext1 {
  color: #34a4f4;
  font-size: 20px;
  margin-right: 50px;
}

.spantext2 {
  color: #5daf34;
  font-size: 20px;
  margin-right: 50px;
}
</style>